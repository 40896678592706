export const GrossPricelistLanguages = [{
    "value": "it",
    "label": "Italiano"
}, {
    "value": "en",
    "label": "English"
}, {
    "value": "de",
    "label": "Deutsch"
}, {
    "value": "es",
    "label": "Español"
}, {
    "value": "fr",
    "label": "Français"
}, {
    "value": "pt",
    "label": "Português"
}, {
    "value": "hu",
    "label": "Magyar"
}, {
    "value": "pl",
    "label": "Polski"
}, {
    "value": "sl",
    "label": "Slovenščina"
}, {
    "value": "cs",
    "label": "Čeština"
}, {
    "value": "zh",
    "label": "中文"
}, {
    "value": "nl",
    "label": "Nederlands"
}, {
    "value": "el",
    "label": "Ελληνική"
}, {
    "value": "ja",
    "label": "日本語"
}, {
    "value": "lv",
    "label": "Latviešu"
}, {
    "value": "ro",
    "label": "Română"
}, {
    "value": "sk",
    "label": "Slovenská"
}, {
    "value": "sv",
    "label": "Svenska"
}, {
    "value": "tr",
    "label": "Türkçe"
}, {
    "value": "hr",
    "label": "Hrvatski"
}];
