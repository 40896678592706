import {ImFilesEmpty} from "react-icons/im";
import client from "../api/client";
import {Button} from "../components/Button"
import {useTranslation} from "react-i18next"
import {downloadResource} from "../constants/DownloadResource";
import {Loader} from "../components/Loader";
import {useEffect, useState} from "react";
import {Title} from "../components/Title";
import {useSelectStyles} from "../hooks/useSelectStyles";
import useGrossPriceLisings from "../api/gross-price-listings/useGrossPriceLisings";
import {format} from "date-fns";
import Select from "react-select";
import {GrossPricelistLanguages} from "../constants/GrossPricelistLanguages";
import {useAuthContext} from "../hooks/use-context/useAuthContext";

export default function GrossPriceList({setTitle}: any) {
    const {t} = useTranslation();

    // hooks
    const {user} = useAuthContext();
    const {priceListing} = useGrossPriceLisings();
    const {CustomStyles} = useSelectStyles();

    // state
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [selectedPriceListing, setSelectedPriceListing] = useState<any>(null);
    //const [selectedPriceListValidity, setSelectedPriceListValidity] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState<any>(format(new Date(), "yyyy-MM-dd"));
    const [selectedPriceListCurrency, setSelectedPriceListCurrency] = useState<any>(null);
    const [language, setLanguage] = useState<any>(GrossPricelistLanguages.filter(option => option.value === user?.interface_language_id));

    // CLIENT
    const handlePricelistiChange = (inputValue: any) => {
        if (inputValue) {
            setSelectedPriceListing(inputValue?.pricelist_id);
            //setSelectedPriceListValidity(inputValue?.validitypricelist);
            setSelectedPriceListCurrency(inputValue?.currency_id);
        } else {
            setSelectedPriceListing(null);
            //setSelectedPriceListValidity(null);
            setSelectedPriceListCurrency(null);
        }
    };


    const downloadPriceListExcel = async (ev: any, type: string) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        const res = await client.get(`reserved-conditions/gross-price-list/${selectedPriceListing}/xlsx/download`, {
            params: {
                //validitypricelist: selectedPriceListValidity,
                validitypricelist: selectedDate,
                currency_id: selectedPriceListCurrency,
                language_id: language[0]?.value,
                "filter[pricelist_type]": type,
            },
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    const downloadPriceListPdf = async (ev: any, type: string) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        const res = await client.get(`reserved-conditions/gross-price-list/${selectedPriceListing}/pdf/download`, {
            params: {
                // validitypricelist: selectedPriceListValidity,
                currency_id: selectedPriceListCurrency,
                validitypricelist: selectedDate,
                language_id: language[0] ? language[0]?.value : "en",
                "filter[pricelist_type]": type,
            },
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    useEffect(() => {
        setTitle(t("Listini prezzi"));
        // update language automatically when user changes interface language
        // setLanguage(AvailableLanguages.filter(option => option.value === user?.interface_language_id));
    }, [user?.interface_language_id]);

    return <>
        {(isDownloadLoading) &&
            <Loader/>}

        <div className="grid grid-cols-1">
            <label className="text-sm">{t("Seleziona listino")}</label>
            <Select
                styles={CustomStyles}
                options={priceListing}
                placeholder={t("Seleziona listino")}
                onChange={handlePricelistiChange}
                escapeClearsValue
                menuPlacement={"bottom"}
                noOptionsMessage={() => t("Nessun risultato")}
            />
        </div>

        <div className="mt-5 grid grid-cols-2 gap-5">
            <div>
                <label className="text-sm">{t("Lingua del listino")}</label>
                <Select
                    styles={CustomStyles}
                    options={GrossPricelistLanguages}
                    placeholder={t("scegli la lingua")}
                    onChange={(e) => setLanguage(GrossPricelistLanguages.filter(option => option.value === e.value))}
                    escapeClearsValue
                    menuPlacement={"bottom"}
                    value={language}
                />
            </div>
            <div>
                <label className="text-sm">{t("Data listino prezzi")}</label>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={ev => setSelectedDate(ev.target.value)}
                />
            </div>
        </div>

        {
            selectedPriceListing &&
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/rothoblaas_black_logo.png" alt="" width={150}/>
                        </div>
                        <span className="text-lg font-semibold text-right">{t("Listino RB")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListExcel(ev, "rb")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListPdf(ev, "rb")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/holz_technic_logo.png" alt="" width={150}/>
                        </div>
                        <span className="text-lg font-semibold text-right">{t("Listino HT")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListExcel(ev, "ht")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListPdf(ev, "ht")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/rothoblaas_black_logo.png" alt="" width={150}/>
                        </div>
                        <span className="text-lg font-semibold text-right">{t("Listino SAFE")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListExcel(ev, "safe")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListPdf(ev, "safe")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/rothoblaas_black_logo.png" alt="" width={150}/>
                        </div>
                        <span className="text-lg font-semibold text-right">{t("Listino ATTREZZATURA")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListExcel(ev, "tools")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                    variant={Button.variant.primary}
                                    onClick={(ev) => downloadPriceListPdf(ev, "tools")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }

        {
            !selectedPriceListing && <>
                <div className="h-96 flex flex-col items-center justify-center space-y-6">
                    <span className="text-9xl text-gray-200"><ImFilesEmpty/></span>
                    <p className="text-2xl text-gray-600">{t("Seleziona un listino per visualizzare i dati.")}</p>
                </div>
            </>
        }

        <div className="pt-6">
            <hr/>

            <div className="mt-6 grid grid-cols-1 gap-5">
                <div className="border p-6 flex flex-col gap-3">

                    <Title variant={Title.variant.secondary} className="pb-4">{t("Altri listini")}</Title>

                    <div className="flex flex-wrap gap-5 mt-4">
                        <Button target="_blank" href="https://rothoblaas.sharepoint.com/:f:/r/sites/rothocommon/SalesForceCommon/PRICELISTS-DISCOUNT%20TABLES-listino%20prezzi/PRICELISTS%20listini/2025%20PRICELISTS%20listini/SERVICE?csf=1&web=1&e=8AAY6u">{t("Listini servizi")}</Button>
                        <Button target="_blank" href="https://rothoblaas.sharepoint.com/:f:/r/sites/rothocommon/SalesForceCommon/PRICELISTS-DISCOUNT%20TABLES-listino%20prezzi/PRICELISTS%20listini/2025%20PRICELISTS%20listini/MAFELL?csf=1&web=1&e=l3AGx4">{t("Listino maffel")}</Button>
                        <Button target="_blank" href="https://rothoblaas.sharepoint.com/:f:/r/sites/rothocommon/SalesForceCommon/PRICELISTS-DISCOUNT%20TABLES-listino%20prezzi/PRICELISTS%20listini/2025%20PRICELISTS%20listini/PERSONALIZED_HELMETS?csf=1&web=1&e=0j2xfa">{t("Contratto caschi personalizzati")}</Button>
                        <Button target="_blank" href="https://rothoblaas.sharepoint.com/:f:/r/sites/rothocommon/SalesForceCommon/PRICELISTS-DISCOUNT%20TABLES-listino%20prezzi/PRICELISTS%20listini/2025%20PRICELISTS%20listini/PERSONALIZED_MEMBRANES?csf=1&web=1&e=23a32d">{t("Contratto teli personalizzati")}</Button>
                    </div>

                </div>
            </div>
        </div>

    </>
}
