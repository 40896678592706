import RothoNotes from "../cart/RothoNotes";
import Select from "react-select";
import ClientReference from "../cart/ClientReference";
import { useTranslation } from "react-i18next";
import { Modal } from "../commom/Modal";
import { Button } from "../Button";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import { useState } from "react";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import { useSplitAvailability } from "../../hooks/useSplitAvailability";

type OrderConfirmationProps = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    handleConfirmClick: (orderClassification: string | null) => Promise<void>,
    clientReference: string,
    data?: any
};

const options = [
    {
        value: 'T',
        label: 'ESERVICE EMPLOYEE FROM CUSTOMER'
    },
    {
        value: 'U',
        label: 'ESERVICE EMPLOYEE FROM SALES FORCE'
    }
];

const OrderConfirmation = ({ open, setOpen, handleConfirmClick, clientReference, data }: OrderConfirmationProps) => {
    const { t } = useTranslation();
    const { can } = useAuthContext();
    const { CustomStyles } = useSelectStyles();
    const { unavailableItems } = useSplitAvailability(data?.items);
    const { delivery_restriction, setDeliveryRestriction } = useShippingContext();

    // Delivery restriction
    const partial_delivery = 1;
    const complete_delivery = 2;
    const partialDeliveryDisabled = unavailableItems?.length <= 0;

    // state
    const [orderClassification, setOrderClassification] = useState<string | null>(null);
    const [classificationError, setClassificationError] = useState<string | null>(null);

    const handleOrderClassificationSelect = (inputValue: any) => {
        if (inputValue?.value) {
            setClassificationError(null);
            setOrderClassification(inputValue?.value);
        } else {
            setOrderClassification(null);
        }
    }

    const handleDeliveryRestrictionChange = (event: any) => {
        setDeliveryRestriction(event?.target?.value);
    };

    const submit = () => {
        if (can(['can_set_order_classification']) && !orderClassification) {
            setClassificationError('- questo campo è obbligatorio');
        } else {
            handleConfirmClick(orderClassification)
        }
    }

    return (
        <Modal title={t("Conferma ordine")} open={open} onClose={setOpen}>
            <div className="space-y-4">
                <ClientReference clientReference={clientReference} />

                <RothoNotes />

                {/* Delivery restriction */}
                {
                    can(["can_see_availability"]) &&
                    <div>
                        <span>{t("Consegna ordine")}</span>
                        <div className="py-2">
                            <label className="flex gap-3 items-center cursor-pointer">
                                <input type="radio" id="partial_delivery" name="delivery" value={1} className="accent-black" checked={delivery_restriction === partial_delivery} disabled={partialDeliveryDisabled} onChange={handleDeliveryRestrictionChange} />
                                <p className={partialDeliveryDisabled ? "text-gray-300" : ""}>
                                    <span className="font-semibold">{t("Consegna parziale")}: </span>
                                    {t("I prodotti verranno evasi in base alla disponibilità dello stock")}
                                </p>
                            </label>
                        </div>
                        <div>
                            <label className="flex gap-3 items-center cursor-pointer">
                                <input type="radio" id="complete_delivery" name="delivery" value={2} className="accent-black" checked={delivery_restriction === complete_delivery} onChange={handleDeliveryRestrictionChange} />
                                <p>
                                    <span className="font-semibold">{t("Consegna completa")}: </span>
                                    {t("I prodotti verranno evasi quando tutti presenti in stock")}
                                </p>
                            </label>
                        </div>
                    </div>
                }

                {
                    can(['can_set_order_classification']) &&
                    <div>
                        <label htmlFor="order_classification">{t("Order source")}</label>
                        <Select
                            id="order_classification"
                            options={options}
                            onChange={handleOrderClassificationSelect}
                            styles={CustomStyles}
                            placeholder={t("select order source")}
                            isClearable
                            escapeClearsValue
                        />
                        <p className="text-sm text-rose-500 mt-1">{classificationError}</p>
                    </div>
                }

                <p>{t("Sei sicuro di confermare il tuo ordine?")}</p>

                <div className="text-right space-x-2 mt-4">
                    <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary} onClick={submit}>{t("Procedi")}</Button>
                </div>
            </div>
        </Modal>
    );
};

export default OrderConfirmation;
