import { useTranslation } from "react-i18next";
import { Title } from "../../Title";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import { EservicesStatus } from "../../../constants/EservicesStatus";

type ShippingInfoSummaryProps = {
    data: any,
    numberFormat: Intl.NumberFormat | null,
    summary: any
};

export default function ShippingInfoSummary({ data, numberFormat, summary }: ShippingInfoSummaryProps) {
    const { t } = useTranslation();
    const { can, user } = useAuthContext();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);
    const orderStatus = EservicesStatus.order.find((status) => status.status === data?.data?.status);

    return (
        <div className={`grid mb-8 font-light text-sm border ${(orderStatus && data?.data?.date) ? "grid-cols-1 md:grid-cols-2 lg:grid-cols-4" : "grid-cols-1 md:grid-cols-3"}`}>
            {/* ADDRESS */}
            <div className={`p-6 ${(orderStatus && data?.data?.date) ? "max-lg:border-b" : "max-md:border-b"}`}>
                <Title variant={Title.variant.secondary} className="mb-4">{t("Indirizzo di consegna")}</Title>
                <div className="uppercase">
                    {
                        data?.data?.address?.new_address ?
                            <>
                                <div>{data?.data?.address?.name}</div>
                                <div className="flex gap-x-1">
                                    {data?.data?.address?.street} {data?.data?.address?.number}{data?.data?.address?.number && ","} {data?.data?.address?.city} {data?.data?.address?.postalcode}
                                </div>
                                <div>{`${data?.data?.address?.region_id} ${data?.data?.address?.country_id}`}</div>
                            </>
                            :
                            <>
                                <div>{data?.data?.deliveryCustomer?.name}</div>
                                <div className="flex gap-x-1">
                                    {data?.data?.deliveryCustomer?.street} {data?.data?.deliveryCustomer?.number}{data?.data?.deliveryCustomer?.number && ","} {data?.data?.deliveryCustomer?.city} {data?.data?.deliveryCustomer?.postalcode}
                                </div>
                                <div>{`${data?.data?.deliveryCustomer?.region_id} ${data?.data?.deliveryCustomer?.country_id}`}</div>
                            </>
                    }
                </div>
            </div>

            {/* PAYMENT METHOD */}
            <div className={`p-6 ${(orderStatus && data?.data?.date) ? "max-lg:border-b md:border-l" : "max-md:border-b md:border-l"}`
            }>
                <Title variant={Title.variant.secondary} className="mb-4">{t("Metodo di pagamento")}</Title>
                <div>
                    <div>{data?.data?.payment_method?.description ?? data?.data?.customer?.finance?.paymentmethod?.description}</div>
                    <div>{data?.data?.payment_term?.description}</div>
                </div>
            </div>

            {/* SHIPPING METHOD */}
            <div className={`p-6 ${(orderStatus && data?.data?.date) ? "max-md:border-b lg:border-l" : "md:border-l"}`}>
                <Title variant={Title.variant.secondary} className="mb-4">{t("Metodo di spedizione")}</Title>
                <div>
                    <>

                        {/* AUTOMATIC FREIGHT COST SET */}
                        {
                            can(["can_see_prices"]) && data.data.shipping_info?.shipping_method && summary?.is_freightcost_calculated &&
                            <div className="first-letter:uppercase space-x-2">
                                <span>{data?.data?.shipping_info?.shipping_method}:</span>
                                <span className="font-normal">{numberFormat?.format(summary?.shipping_method_without_iva)}</span>
                            </div>
                        }

                        {/* SALES ORDER FREIGHT COST SET */}
                        {/* {
                            !data?.data?.shipping_info?.manual_freight_cost && summary?.shipping_method_without_iva > 0 &&
                            <div className="first-letter:uppercase space-x-2">
                                <span>{data?.data?.shipping_info?.shipping_method}:</span>
                                <span className="font-normal">{numberFormat?.format(summary?.shipping_method_without_iva)}</span>
                            </div>
                        } */}

                        {/* NO FREIGHT COST */}
                        {
                            (!data?.data?.shipping_info?.manual_freight_cost && !data.data.shipping_info?.shipping_method && !summary?.shipping_method_without_iva && summary?.shipping_method_without_iva !== 0) &&
                            <div>{t("Non è stato possibile calcolare i costi di spedizione")}</div>
                        }

                        <div className="flex gap-x-2">
                            {/* 1 partial delivery, 2 complete delivery */}
                            <span className="font-light">{data?.data?.deliveryRestriction === 1 ? <span>{t("Consegna")}: {t("parziale")}</span> : data?.data?.deliveryRestriction === 2 ? <span>{t("Consegna")}: {t("completa")}</span> : ""}</span>
                            {/* TOTAL WEIGHT */}
                            {
                                data?.data?.total_weight &&
                                <>
                                    {
                                        !data?.data?.salesOrder &&
                                        <div>
                                            <span>{t("Peso totale")}: </span>
                                            <span className="font-medium">{data?.data?.total_weight} kg</span>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </>
                </div>
            </div>

            {
                orderStatus && data?.data?.date &&
                <div className="p-6 flex flex-col md:border-l">
                    <Title variant={Title.variant.secondary} className="mb-4">{t("Dettagli")}</Title>
                    <div>
                        <span>{t("Data ordine")}: </span>
                        <span className="font-light">{data?.data?.date ? dateFormat?.format(new Date(data?.data?.date)) : ""}</span>
                    </div>
                    <div>
                        <span>{t("Stato ordine")}: </span>
                        <span className="font-light">{orderStatus?.description && t(orderStatus?.description)}</span>
                    </div>
                </div>
            }
        </div>
    );
}
