import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "../client";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

export default function useCart(id: string | undefined) {

    const navigate = useNavigate();
    const { can } = useAuthContext();

    const { setAdditionalFreightCost, setManualFreightCost, setManualShippingMethod, setRothoNotes, setDeliveryRestriction } = useShippingContext();

    // GET SINGLE CART INFO
    const getCartInfo = async (id: string | undefined) => {
        const res = await client.get(`carts/${id}`);
        return res.data;
    };

    // react query
    const { data, isLoading, isFetching } = useQuery(["cart", id], () => getCartInfo(id), {
        refetchOnWindowFocus: false,
        retry: false,
        // issue #340
        refetchInterval: 1000 * 60 * 10, // 10 minutes
        onSuccess: (data: any) => {
            // SET DELIVERY RESTRICTION
            if (can(["can_see_availability"])) {
                setDeliveryRestriction(data?.data?.deliveryRestriction ?? 1);
            } else {
                // set partial delivery if the user cannot se availability - issue #397
                setDeliveryRestriction(1);
            }

            // SET ROTHO NOTES
            if (data?.data?.note) {
                setRothoNotes(data?.data?.note);
            } else {
                setRothoNotes("");
            }

            // SET ADDITIONAL/MANUAL FREIGHT COSTS
            if (data?.data?.shipping_info?.additional_freight_cost) {
                if (!data?.data?.address?.new_address) {
                    setAdditionalFreightCost(data?.data?.shipping_info?.additional_freight_cost);
                } else {
                    setAdditionalFreightCost("");
                }
            }
            if (data?.data?.shipping_info?.manual_freight_cost) {
                setManualFreightCost(data?.data?.shipping_info?.manual_freight_cost);
            }
            if (data?.data?.shipping_info?.manual_shipping_method) {
                setManualShippingMethod(data?.data?.shipping_info?.manual_shipping_method);
            }

            // SET CUSTOMER
            const customer = data.data.customer;
            if (customer) {
                setSelectedCustomer({ ...customer, label: `${customer.name} - ${customer.code} - ${customer.city} - ${customer.ts_fullname}` });
            } else {
                setSelectedCustomer(null);
            }
        },
        onError: () => {
            navigate("/carts");
        }
    });

    // cart customer state
    const [selectedCustomer, setSelectedCustomer] = useState(data?.data?.customer || null);

    // useEffect(() => {
    // console.log(selectedCustomer);
    // }, [selectedCustomer]);

    return { data, isLoading, isFetching, selectedCustomer, setSelectedCustomer };
}
