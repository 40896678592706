import { useTranslation } from "react-i18next";
import { Status } from "../Status";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { AvailabilityTooltip } from "../cart/AvailabilityTooltip";
import { FaPencilAlt } from "react-icons/fa";
import { useState } from "react";
import ChangeProductModal from "../cart/step_2/Delivery-Availability/ChangeProductModal";
import { Tooltip } from "@reach/tooltip";
import useAlternativeProducts from "../../api/alternative-products/useAlternativeProducts";

export const AvailabilityCell = (props: any) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    // state
    const [open, setOpen] = useState<any>(false);

    // react query
    const { data: alternativeItems } = useAlternativeProducts({ product_id: props.row.original.code, "filter[customer_code]": props?.cart?.data?.customer?.code }, open);

    const numberFormat = new Intl.NumberFormat(user?.language_id);

    const availableQuantity = props.row.original.availabilityInfo?.availableQuantity;
    const unavailableQuantity = props.row.original.availabilityInfo?.unavailableQuantity;
    const availabilityCoverDate = props.row.original.availabilityInfo?.availabilityCoverDate;

    return (
        <div className="space-y-1">
            {
                open &&
                <ChangeProductModal
                    open={open}
                    onClose={setOpen}
                    alternativeItems={alternativeItems?.data}
                    {...props}
                />
            }

            {
                availableQuantity > 0 &&
                <div className="flex gap-x-1 items-center">
                    <div className="flex items-center gap-x-2 border rounded-md w-fit px-3 py-0.5 shadow-[0px_1px_2px_0px_rgba(206,206,206,0.25)]">
                        <Status variant={Status.variant["circled_green"]} />
                        <div className="flex gap-x-1 items-center">
                            <span className="font-normal">{numberFormat.format(availableQuantity)}</span>
                        </div>
                    </div>
                </div>
            }

            {
                unavailableQuantity > 0 &&
                <div className="flex items-center gap-x-1">
                    <div className="flex gap-x-2 items-center border rounded-md w-fit px-3 py-0.5 shadow-[0px_1px_2px_0px_rgba(206,206,206,0.25)]">
                        <Status variant={Status.variant["circled_red"]}>
                        </Status>

                        <div className="flex gap-x-1">
                            {/* QTY */}
                            <span className="font-normal">{numberFormat.format(unavailableQuantity)}</span>
                            <AvailabilityTooltip availability_date={availabilityCoverDate} />
                        </div>
                    </div>

                    {/* ALTERNATIVE PRODUCT */}
                    {
                        !props.isSummary &&
                        <Tooltip
                            label={t("Modifica")}
                            style={{
                                backgroundColor: "#F1F5F6",
                                borderRadius: "3px",
                                fontWeight: "300",
                                marginRight: "20px",
                                zIndex: 50
                            }}
                        >
                            <span>
                                <FaPencilAlt className='w-3 h-3 font-normal text-sm cursor-pointer' onClick={() => setOpen(true)} />
                            </span>
                        </Tooltip>
                    }
                </div>
            }
        </div>
    );
};
