import { useTranslation } from "react-i18next";
import { Title } from "../Title";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { Status, StatusVariant } from "../Status";

type Props = {
    data: any
};

export const OfferData = ({ data }: Props) => {
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const isExpired = data?.data?.offer_expired;
    const offer_state = data?.data?.status;

    let expiry_days = 30;
    if (data?.data?.customer?.organization_id === "AR001") {
        expiry_days = 3;
    }

    const offer_date = offer_state === "requested" ? new Date(data?.data?.offer_requested_at) : new Date(data?.data?.offer_proposed_at);
    const offer_expiry_date = offer_state === "proposed" ? new Date(data?.data?.offer_proposed_at) : null;

    if (offer_expiry_date) {
        offer_expiry_date.setDate(offer_expiry_date.getDate() + expiry_days);
    }

    let state: keyof typeof StatusVariant;
    let description;

    if (isExpired) {
        state = "amber";
        description = t("Scaduta").toString();
    } else {
        switch (offer_state) {
            case "requested":
                state = "violet";
                description = t("Richiesta").toString();
                break;
            case "proposed":
                state = "yellow";
                description = !roles(user?.constants?.external_roles) ? t("Aperta").toString() : t("Ricevuta").toString();
                break;
            case "accepted":
                state = "green";
                description = t("Accettata").toString();
                break;
            case "rejected":
                state = "red";
                description = t("Rifiutata").toString();
                break;
            case "partially_confirmed":
                state = "brown";
                description = t("Parzialmente confermata").toString();
                break;
            default:
                state = "blue";
                break;
        }
    }

    return (
        <div className="p-6 mb-8 border text-sm">
            <div className="font-light space-y-2">
                <Title variant={Title.variant.secondary}>{t("Dati offerta")}</Title>

                <Status variant={Status.variant[state]}>{description}</Status>

                <div className="space-y-2">
                    {/* data creazione */}
                    <div className="space-x-1">
                        <span>
                            {t("Data creazione")}:
                        </span>
                        <span className="font-normal">
                            {dateFormat.format(offer_date)}
                        </span>
                    </div>

                    {/* data scadenza */}
                    {
                        offer_expiry_date &&
                        <div className="space-x-1">
                            <span>
                                {t("Data scadenza")}:
                            </span>
                            <span className="font-normal">
                                {dateFormat.format(offer_expiry_date)}
                            </span>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
};
