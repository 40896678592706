import client from "../client";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import { useDiscountTableContext } from "../../hooks/use-context/useDiscountTableContext";
import { useFormContext } from "react-hook-form";

type useDiscountSearchParams = {
    "filter[class]": string,
    "filter[search]": string,
    "filter[customer_code]": string,
    "filter[classes_only]": any,
};

const useGetProductClasses = (data: any, classes_only: boolean) => {
    const { t } = useTranslation();
    const {
        customerData,
        updates
    } = useDiscountTableContext();

    const { getValues } = useFormContext();

    const getProducts = useCallback(async (value: string) => {
        const params: useDiscountSearchParams = {
            "filter[class]": data?.subClass ?? "",
            "filter[search]": value ?? data?.subClass,
            "filter[customer_code]": customerData?.code ?? "",
            "filter[classes_only]": classes_only ? 1 : 0,
        };

        const res = await client.get("discount-table/price-classes", { params: params });

        const results: any = [];
        const itemsCode: string[] = Object.keys(getValues()?.data ?? {});

        const existingItems: any[] = [];
        updates.find((e: any) => {
            if (e?.id) {
                existingItems.push(e.id);
            } else if (e?.code) {
                existingItems.push(e.code);
            }
        });

        res.data.data.forEach((item: any) => {
            // console.log("ITEMS");
            // console.log(itemsCode);
            // console.log(existingItems);
            // console.log(item);
            const itemid = item?.id || item?.code;
            results.push({
                label:
                    <span>{item?.code.indexOf("-") === 1 ? item?.code.substring(2) : item?.code}
                        {item?.replacing_code ? <span
                            className='font-bold p-1 rounded'>{t("Vecchio codice")}: {item?.replacing_code}
                        </span> : null} - {item.description}
                    </span>,
                value: item,
                disabled: itemsCode.includes(itemid) || existingItems.includes(itemid),
            });
        });

        return results;
    }, [/*cart?.data?.customer?.code*/]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    return { loadItemsOptions };
};

export default useGetProductClasses;
