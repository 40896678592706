import { useEffect, useRef, useState } from "react";
import SubRows from "./SubRows";
import client from "../../api/client";

// SUB ROW FETCH COMPONENT
const SubRowAsync = ({ row, endpoint }: any) => {
    const alreadyFetched = useRef(false);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const id = endpoint === 'orders' ? row.original.order_id : row.original.id;
        client.get(`${endpoint}/${id}`)
            .then(res => {
                if (alreadyFetched.current) {
                    return;
                }
                setData(res.data.data);
                setLoading(false);
            });
        return () => { alreadyFetched.current = true; };
    }, [row.original.id]);

    return (
        <SubRows
            data={data}
            loading={loading}
            endpoint={endpoint}
        />
    );
};

export default SubRowAsync;