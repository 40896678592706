import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import client from "../client";
import { useTranslation } from "react-i18next";

const useUsers = () => {
    const { t } = useTranslation();
    // GET USERS
    const getUsers = useCallback(async (value: string) => {
        const params = { searchString: value };
        const res = await client.get("user-management/users", { params: params });

        return res.data.users.map((user: any) => {
            const customers_code = user?.customers?.map((customer: any) => customer?.code);
            const customersCodeFormatted = customers_code && customers_code.length > 0 ? `(${customers_code.join(', ')})` : '';
            return {
                label: <span>{user.name} - {user.email} {customersCodeFormatted} {user.isActive && <>{" - "}<span className="text-green-500">{t("ATTIVO")}</span></>}</span>,
                value: user
            };
        });
    }, []);

    const loadUsersOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getUsers(value).then((options) => callback(options));
        }, 1000);
    }, [getUsers]);

    return { loadUsersOptions };
};

export default useUsers;
