import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import client from "../../api/client";
import { downloadResource } from "../../constants/DownloadResource";
import { Status, StatusVariant } from "../Status";
import { useTable, useSortBy, usePagination, Column, useExpanded } from "react-table";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { Button } from "../Button";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { SortableHeader } from "../commom/SortableHeader";
import { EservicesStatus } from "../../constants/EservicesStatus";
import { Tooltip } from "@reach/tooltip";
import { ShippingsSearchParams } from "./ShippingsSearchBar";
import { useSearchParams } from "react-router-dom";

type ShippingsTableProps = {
    items: any,
    setSearchParams: any
    renderRowSubComponent: any
};

const handleDownloadClick = async (ev: any, id: string) => {
    ev.preventDefault();

    const res = await client.get(`/shippings/${id}/download`, {
        headers: {
            responseType: "blob"
        }
    });

    // mode download
    if (res.data && res.data.content && res.data.name && res.data.type) {
        const filetype = res.data.type;
        const content = res.data.content;
        const filename = res.data.name;

        downloadResource(content, filename, filetype);
    }
};

export const ShippingsTable = ({ renderRowSubComponent, items, setSearchParams }: ShippingsTableProps) => {
    const { user, roles } = useAuthContext();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    //state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    // formatter per le date
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);


    // table data
    const data = useMemo(() => items, [items]);

    const columns: Array<Column<any>> = useMemo(() => [
        {
            id: "expander",
            Header: "",
            disableSortBy: true,
            className: "border-b p-3",
            Cell: ({ row }: any) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row

                <span
                    {...row.getToggleRowExpandedProps({
                        style: {
                            // We can even use the row.depth property
                            // and paddingLeft to indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                        tabIndex: 0
                    })}
                >
                    {row.isExpanded ? <HiChevronUp className="text-xl" /> : <HiChevronDown className="text-xl" />}
                </span>
        },
        {
            Header: (props) => {
                return <SortableHeader props={props} title={t("Numero spedizione").toString()} />;
            },
            accessor: "slipnumber",
            className: "border-b p-3",
        },
        {
            Header: t("Nota personalizzata").toString(),
            accessor: "referencenumber",
            disableSortBy: true,
            maxWidth: 150,
            className: "border-b p-4 text truncate text-left",
            Cell: (props) => {
                if (!props.row.original.referencenumber) return "-";

                return <Tooltip
                    label={props.row.original.referencenumber}
                    style={{
                        backgroundColor: "#F1F5F6",
                        borderRadius: "3px",
                        fontWeight: "300",
                        marginRight: "20px",
                        zIndex: 50
                    }}
                >
                    <span>{props.row.original.referencenumber}</span>
                </Tooltip>;
            }
        },
        {
            Header: t("Codice cliente").toString(),
            accessor: "customer_id",
            className: "border-b p-3",
            disableSortBy: true,
        },
        {
            Header: t("Nome cliente").toString(),
            accessor: "customer_name",
            className: "border-b p-3",
            disableSortBy: true,
        },
        {
            Header: t("Indirizzo di consegna").toString(),
            className: "border-b p-3 truncate",
            maxWidth: 150,
            Cell: (props) => {
                const deliveryCustomer = props.row.original.deliveryCustomer;
                const deliveryAddress = <span>{`${deliveryCustomer?.name} - ${deliveryCustomer?.city}, ${deliveryCustomer?.region_id} - ${deliveryCustomer?.postalcode} - ${deliveryCustomer?.street} - ${deliveryCustomer?.country_id}`}</span>;

                // return <span>{deliveryAddress}</span>

                return <Tooltip
                    label={deliveryAddress}
                    style={{
                        backgroundColor: "#F1F5F6",
                        borderRadius: "3px",
                        fontWeight: "300",
                        whiteSpace: "pre-wrap",
                        marginRight: "20px",
                        overflowWrap: "break-word",
                        zIndex: 50
                    }}
                >
                    <span>{deliveryAddress}</span>
                </Tooltip>;
            }
        },
        {
            Header: t("Technical Salesman").toString(),
            accessor: "technical_salesman",
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const customer = props.row.original.customer;

                // return <span>{customer?.ts_code}</span>
                return <span>{customer?.ts_fullname}</span>;
            }
        },
        {
            Header: (props) => {
                return <SortableHeader props={props} title={t("Data spedizione").toString()} />;
            },
            accessor: "date",
            className: "border-b p-3",
            Cell: props => {
                const emittedData = new Date(props.row.original.date.replace(/-/g, "\/").replace(/T.+/, ""));
                const date = props.dateFormat.format(emittedData);
                return <span>{date}</span>;
            }
        },
        {
            Header: (props) => {
                return <SortableHeader props={props} title={t("Stato spedizione").toString()} />;
            },
            accessor: "status",
            className: "border-b p-3",
            Cell: (props: any) => {
                const shippingStatus = props.row.original.status;
                const status = EservicesStatus.shipping.find((status) => status.status === shippingStatus);

                const statusVariant = status?.variant as keyof typeof StatusVariant;

                if (!statusVariant) return "";

                return <Status variant={Status.variant[statusVariant]}>{t(status?.description ?? "")}</Status>;
            }
        },
        {
            Header: t("Tracking").toString(),
            className: "border-b p-3",
            disableSortBy: true,
            Cell: (props) => props.row.original.tracking ? <Button target={"_blank"} href={props.row.original.tracking} variant={Button.variant.primary}>Tracking</Button> : t("Nessuna azione disponibile").toString()
        },
        {
            Header: t("Documento di trasporto").toString(),
            className: "border-b p-3",
            disableSortBy: true,
            Cell: (props) => <Button onClick={ev => handleDownloadClick(ev, props.row.original.id)} variant={Button.variant.secondary}>{t("Download")}</Button>
        },
    ], [t, hiddenColumns]);

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { sortBy }
    } = useTable({
        columns,
        data,
        defaultColumn,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: { hiddenColumns: hiddenColumns, pageSize: 10 },
        dateFormat
    }, useSortBy, useExpanded, usePagination);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (roles(user?.constants?.external_roles)) {
            setHiddenColumns(["technical_salesman"]);
        } else {
            setHiddenColumns([]);
        }
    }, [data]);

    // permetto l'ordinamento in tabella delle colonne number (numero spedizione) e date (data spedizione)
    useEffect(() => {
        if (["slipnumber", "date", "status"].includes(sortBy[0]?.id))
            setSearchParams(() => ({ ...Object.fromEntries(searchParams.entries()), sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}`, page: 1 }));
    }, [sortBy]);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light overflow-x-auto block xl:table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    <span className='flex items-center gap-x-1'>
                                        {column.render("Header")}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            // Use a React.Fragment here so the table markup is still valid
                            <React.Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).className, style: { width: cell.column.width, maxWidth: cell.column.maxWidth } })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length} className="border">
                                            {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                            */}
                                            {renderRowSubComponent({ row, rowProps: row.getRowProps(), visibleColumns })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
                {/* <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className="border-b last:border-b-0">
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                            className="px-4 py-3 first:font-normal"
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody> */}
            </table>
        </>
    );
};
