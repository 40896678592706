import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import Select from "react-select";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { useSearchParams } from "react-router-dom";

type CustomerSelectProps = {
    loadCustomersOptions: any;
    handleChange: any;
    label: string;
    error?: any;
    selectedCustomer?: any;
    setSelectedCustomer?: any;
};

export const CustomerSelect = forwardRef(({ loadCustomersOptions, handleChange, error, label, selectedCustomer, setSelectedCustomer }: CustomerSelectProps, ref: any) => {
    // hooks
    const { roles, user } = useAuthContext();
    const { t } = useTranslation();
    const { CustomStyles } = useSelectStyles();
    const [searchParams] = useSearchParams();

    const [openCustomer, setOpenCustomer] = useState<boolean>(false);

    const options: any[] = [];
    user?.customers?.forEach((customer: any) => {
        options.push(
            { ...customer, label: `${customer.name} - ${customer.code} - ${customer.city} - ${customer.ts_fullname}` }
        );
    });

    const getDefaultCustomer = (): any => {
        let customer = null;
        searchParams.forEach((value: any, key: any) => {
            if (key === "filter[customer_code]") {
                customer = value;
            }
        });

        return customer ? { value: customer, label: customer } : undefined;
    };

    useEffect(() => {
        if (options.length === 1 && roles(user?.constants?.external_roles)) {
            handleChange(options[0]);
        }
    }, []);

    return (
        <div>
            <label htmlFor="">{t(label)}</label>
            {/* CUSTOMER SELECT */}
            {!roles(user?.constants?.external_roles) ? (
                <AsyncSelect
                    ref={ref}
                    value={selectedCustomer}
                    loadOptions={loadCustomersOptions}
                    defaultValue={getDefaultCustomer()}
                    onChange={handleChange}
                    onInputChange={(value: any) => setOpenCustomer(value !== "")}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    styles={CustomStyles}
                    placeholder={t("inserisci nome o codice cliente...")}
                    loadingMessage={() => t("Caricamento in corso...")}
                    noOptionsMessage={() => t("Nessun risultato")}
                    openMenuOnClick={false}
                    isClearable
                    escapeClearsValue
                    menuIsOpen={openCustomer}
                />
            ) : (
                <Select
                    ref={ref}
                    options={options}
                    defaultValue={getDefaultCustomer()}
                    value={selectedCustomer}
                    isDisabled={options.length === 1}
                    onChange={handleChange}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    styles={CustomStyles}
                    placeholder={t("inserisci nome o codice cliente...")}
                    noOptionsMessage={() => t("Nessun risultato")}
                    isClearable
                    escapeClearsValue
                />
            )}
            {error && <p className="text-sm text-rose-500 mt-1">{error.message}</p>}
        </div>
    );
});
