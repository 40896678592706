export const phonePrefixies = [
    {
        country: "Afghanistan",
        prefix: "+93",
        code: "AF"
    },
    {
        country: "Aland Islands",
        prefix: "+358",
        code: "AX"
    },
    {
        country: "Albania",
        prefix: "+355",
        code: "AL"
    },
    {
        country: "Algeria",
        prefix: "+213",
        code: "DZ"
    },
    {
        country: "AmericanSamoa",
        prefix: "+1684",
        code: "AS"
    },
    {
        country: "Andorra",
        prefix: "+376",
        code: "AD"
    },
    {
        country: "Angola",
        prefix: "+244",
        code: "AO"
    },
    {
        country: "Anguilla",
        prefix: "+1264",
        code: "AI"
    },
    {
        country: "Antarctica",
        prefix: "+672",
        code: "AQ"
    },
    {
        country: "Antigua and Barbuda",
        prefix: "+1268",
        code: "AG"
    },
    {
        country: "Argentina",
        prefix: "+54",
        code: "AR"
    },
    {
        country: "Armenia",
        prefix: "+374",
        code: "AM"
    },
    {
        country: "Aruba",
        prefix: "+297",
        code: "AW"
    },
    {
        country: "Australia",
        prefix: "+61",
        code: "AU"
    },
    {
        country: "Austria",
        prefix: "+43",
        code: "AT"
    },
    {
        country: "Azerbaijan",
        prefix: "+994",
        code: "AZ"
    },
    {
        country: "Bahamas",
        prefix: "+1242",
        code: "BS"
    },
    {
        country: "Bahrain",
        prefix: "+973",
        code: "BH"
    },
    {
        country: "Bangladesh",
        prefix: "+880",
        code: "BD"
    },
    {
        country: "Barbados",
        prefix: "+1246",
        code: "BB"
    },
    {
        country: "Belarus",
        prefix: "+375",
        code: "BY"
    },
    {
        country: "Belgium",
        prefix: "+32",
        code: "BE"
    },
    {
        country: "Belize",
        prefix: "+501",
        code: "BZ"
    },
    {
        country: "Benin",
        prefix: "+229",
        code: "BJ"
    },
    {
        country: "Bermuda",
        prefix: "+1441",
        code: "BM"
    },
    {
        country: "Bhutan",
        prefix: "+975",
        code: "BT"
    },
    {
        country: "Bolivia, Plurinational State of",
        prefix: "+591",
        code: "BO"
    },
    {
        country: "Bosnia and Herzegovina",
        prefix: "+387",
        code: "BA"
    },
    {
        country: "Botswana",
        prefix: "+267",
        code: "BW"
    },
    {
        country: "Brazil",
        prefix: "+55",
        code: "BR"
    },
    {
        country: "British Indian Ocean Territory",
        prefix: "+246",
        code: "IO"
    },
    {
        country: "Brunei Darussalam",
        prefix: "+673",
        code: "BN"
    },
    {
        country: "Bulgaria",
        prefix: "+359",
        code: "BG"
    },
    {
        country: "Burkina Faso",
        prefix: "+226",
        code: "BF"
    },
    {
        country: "Burundi",
        prefix: "+257",
        code: "BI"
    },
    {
        country: "Cambodia",
        prefix: "+855",
        code: "KH"
    },
    {
        country: "Cameroon",
        prefix: "+237",
        code: "CM"
    },
    {
        country: "Canada",
        prefix: "+1",
        code: "CA"
    },
    {
        country: "Cape Verde",
        prefix: "+238",
        code: "CV"
    },
    {
        country: "Cayman Islands",
        prefix: "+ 345",
        code: "KY"
    },
    {
        country: "Central African Republic",
        prefix: "+236",
        code: "CF"
    },
    {
        country: "Chad",
        prefix: "+235",
        code: "TD"
    },
    {
        country: "Chile",
        prefix: "+56",
        code: "CL"
    },
    {
        country: "China",
        prefix: "+86",
        code: "CN"
    },
    {
        country: "Christmas Island",
        prefix: "+61",
        code: "CX"
    },
    {
        country: "Cocos (Keeling) Islands",
        prefix: "+61",
        code: "CC"
    },
    {
        country: "Colombia",
        prefix: "+57",
        code: "CO"
    },
    {
        country: "Comoros",
        prefix: "+269",
        code: "KM"
    },
    {
        country: "Congo",
        prefix: "+242",
        code: "CG"
    },
    {
        country: "Congo, The Democratic Republic of the Congo",
        prefix: "+243",
        code: "CD"
    },
    {
        country: "Cook Islands",
        prefix: "+682",
        code: "CK"
    },
    {
        country: "Costa Rica",
        prefix: "+506",
        code: "CR"
    },
    {
        country: "Cote d'Ivoire",
        prefix: "+225",
        code: "CI"
    },
    {
        country: "Croatia",
        prefix: "+385",
        code: "HR"
    },
    {
        country: "Cuba",
        prefix: "+53",
        code: "CU"
    },
    {
        country: "Cyprus",
        prefix: "+357",
        code: "CY"
    },
    {
        country: "Czech Republic",
        prefix: "+420",
        code: "CZ"
    },
    {
        country: "Denmark",
        prefix: "+45",
        code: "DK"
    },
    {
        country: "Djibouti",
        prefix: "+253",
        code: "DJ"
    },
    {
        country: "Dominica",
        prefix: "+1767",
        code: "DM"
    },
    {
        country: "Dominican Republic",
        prefix: "+1849",
        code: "DO"
    },
    {
        country: "Ecuador",
        prefix: "+593",
        code: "EC"
    },
    {
        country: "Egypt",
        prefix: "+20",
        code: "EG"
    },
    {
        country: "El Salvador",
        prefix: "+503",
        code: "SV"
    },
    {
        country: "Equatorial Guinea",
        prefix: "+240",
        code: "GQ"
    },
    {
        country: "Eritrea",
        prefix: "+291",
        code: "ER"
    },
    {
        country: "Estonia",
        prefix: "+372",
        code: "EE"
    },
    {
        country: "Ethiopia",
        prefix: "+251",
        code: "ET"
    },
    {
        country: "Falkland Islands (Malvinas)",
        prefix: "+500",
        code: "FK"
    },
    {
        country: "Faroe Islands",
        prefix: "+298",
        code: "FO"
    },
    {
        country: "Fiji",
        prefix: "+679",
        code: "FJ"
    },
    {
        country: "Finland",
        prefix: "+358",
        code: "FI"
    },
    {
        country: "France",
        prefix: "+33",
        code: "FR"
    },
    {
        country: "French Guiana",
        prefix: "+594",
        code: "GF"
    },
    {
        country: "French Polynesia",
        prefix: "+689",
        code: "PF"
    },
    {
        country: "Gabon",
        prefix: "+241",
        code: "GA"
    },
    {
        country: "Gambia",
        prefix: "+220",
        code: "GM"
    },
    {
        country: "Georgia",
        prefix: "+995",
        code: "GE"
    },
    {
        country: "Germany",
        prefix: "+49",
        code: "DE"
    },
    {
        country: "Ghana",
        prefix: "+233",
        code: "GH"
    },
    {
        country: "Gibraltar",
        prefix: "+350",
        code: "GI"
    },
    {
        country: "Greece",
        prefix: "+30",
        code: "GR"
    },
    {
        country: "Greenland",
        prefix: "+299",
        code: "GL"
    },
    {
        country: "Grenada",
        prefix: "+1473",
        code: "GD"
    },
    {
        country: "Guadeloupe",
        prefix: "+590",
        code: "GP"
    },
    {
        country: "Guam",
        prefix: "+1671",
        code: "GU"
    },
    {
        country: "Guatemala",
        prefix: "+502",
        code: "GT"
    },
    {
        country: "Guernsey",
        prefix: "+44",
        code: "GG"
    },
    {
        country: "Guinea",
        prefix: "+224",
        code: "GN"
    },
    {
        country: "Guinea-Bissau",
        prefix: "+245",
        code: "GW"
    },
    {
        country: "Guyana",
        prefix: "+595",
        code: "GY"
    },
    {
        country: "Haiti",
        prefix: "+509",
        code: "HT"
    },
    {
        country: "Holy See (Vatican City State)",
        prefix: "+379",
        code: "VA"
    },
    {
        country: "Honduras",
        prefix: "+504",
        code: "HN"
    },
    {
        country: "Hong Kong",
        prefix: "+852",
        code: "HK"
    },
    {
        country: "Hungary",
        prefix: "+36",
        code: "HU"
    },
    {
        country: "Iceland",
        prefix: "+354",
        code: "IS"
    },
    {
        country: "India",
        prefix: "+91",
        code: "IN"
    },
    {
        country: "Indonesia",
        prefix: "+62",
        code: "ID"
    },
    {
        country: "Iran, Islamic Republic of Persian Gulf",
        prefix: "+98",
        code: "IR"
    },
    {
        country: "Iraq",
        prefix: "+964",
        code: "IQ"
    },
    {
        country: "Ireland",
        prefix: "+353",
        code: "IE"
    },
    {
        country: "Isle of Man",
        prefix: "+44",
        code: "IM"
    },
    {
        country: "Israel",
        prefix: "+972",
        code: "IL"
    },
    {
        country: "Italy",
        prefix: "+39",
        code: "IT"
    },
    {
        country: "Jamaica",
        prefix: "+1876",
        code: "JM"
    },
    {
        country: "Japan",
        prefix: "+81",
        code: "JP"
    },
    {
        country: "Jersey",
        prefix: "+44",
        code: "JE"
    },
    {
        country: "Jordan",
        prefix: "+962",
        code: "JO"
    },
    {
        country: "Kazakhstan",
        prefix: "+77",
        code: "KZ"
    },
    {
        country: "Kenya",
        prefix: "+254",
        code: "KE"
    },
    {
        country: "Kiribati",
        prefix: "+686",
        code: "KI"
    },
    {
        country: "Korea, Democratic People's Republic of Korea",
        prefix: "+850",
        code: "KP"
    },
    {
        country: "Korea, Republic of South Korea",
        prefix: "+82",
        code: "KR"
    },
    {
        country: "Kuwait",
        prefix: "+965",
        code: "KW"
    },
    {
        country: "Kyrgyzstan",
        prefix: "+996",
        code: "KG"
    },
    {
        country: "Laos",
        prefix: "+856",
        code: "LA"
    },
    {
        country: "Latvia",
        prefix: "+371",
        code: "LV"
    },
    {
        country: "Lebanon",
        prefix: "+961",
        code: "LB"
    },
    {
        country: "Lesotho",
        prefix: "+266",
        code: "LS"
    },
    {
        country: "Liberia",
        prefix: "+231",
        code: "LR"
    },
    {
        country: "Libyan Arab Jamahiriya",
        prefix: "+218",
        code: "LY"
    },
    {
        country: "Liechtenstein",
        prefix: "+423",
        code: "LI"
    },
    {
        country: "Lithuania",
        prefix: "+370",
        code: "LT"
    },
    {
        country: "Luxembourg",
        prefix: "+352",
        code: "LU"
    },
    {
        country: "Macao",
        prefix: "+853",
        code: "MO"
    },
    {
        country: "Macedonia",
        prefix: "+389",
        code: "MK"
    },
    {
        country: "Madagascar",
        prefix: "+261",
        code: "MG"
    },
    {
        country: "Malawi",
        prefix: "+265",
        code: "MW"
    },
    {
        country: "Malaysia",
        prefix: "+60",
        code: "MY"
    },
    {
        country: "Maldives",
        prefix: "+960",
        code: "MV"
    },
    {
        country: "Mali",
        prefix: "+223",
        code: "ML"
    },
    {
        country: "Malta",
        prefix: "+356",
        code: "MT"
    },
    {
        country: "Marshall Islands",
        prefix: "+692",
        code: "MH"
    },
    {
        country: "Martinique",
        prefix: "+596",
        code: "MQ"
    },
    {
        country: "Mauritania",
        prefix: "+222",
        code: "MR"
    },
    {
        country: "Mauritius",
        prefix: "+230",
        code: "MU"
    },
    {
        country: "Mayotte",
        prefix: "+262",
        code: "YT"
    },
    {
        country: "Mexico",
        prefix: "+52",
        code: "MX"
    },
    {
        country: "Micronesia, Federated States of Micronesia",
        prefix: "+691",
        code: "FM"
    },
    {
        country: "Moldova",
        prefix: "+373",
        code: "MD"
    },
    {
        country: "Monaco",
        prefix: "+377",
        code: "MC"
    },
    {
        country: "Mongolia",
        prefix: "+976",
        code: "MN"
    },
    {
        country: "Montenegro",
        prefix: "+382",
        code: "ME"
    },
    {
        country: "Montserrat",
        prefix: "+1664",
        code: "MS"
    },
    {
        country: "Morocco",
        prefix: "+212",
        code: "MA"
    },
    {
        country: "Mozambique",
        prefix: "+258",
        code: "MZ"
    },
    {
        country: "Myanmar",
        prefix: "+95",
        code: "MM"
    },
    {
        country: "Namibia",
        prefix: "+264",
        code: "NA"
    },
    {
        country: "Nauru",
        prefix: "+674",
        code: "NR"
    },
    {
        country: "Nepal",
        prefix: "+977",
        code: "NP"
    },
    {
        country: "Netherlands",
        prefix: "+31",
        code: "NL"
    },
    {
        country: "Netherlands Antilles",
        prefix: "+599",
        code: "AN"
    },
    {
        country: "New Caledonia",
        prefix: "+687",
        code: "NC"
    },
    {
        country: "New Zealand",
        prefix: "+64",
        code: "NZ"
    },
    {
        country: "Nicaragua",
        prefix: "+505",
        code: "NI"
    },
    {
        country: "Niger",
        prefix: "+227",
        code: "NE"
    },
    {
        country: "Nigeria",
        prefix: "+234",
        code: "NG"
    },
    {
        country: "Niue",
        prefix: "+683",
        code: "NU"
    },
    {
        country: "Norfolk Island",
        prefix: "+672",
        code: "NF"
    },
    {
        country: "Northern Mariana Islands",
        prefix: "+1670",
        code: "MP"
    },
    {
        country: "Norway",
        prefix: "+47",
        code: "NO"
    },
    {
        country: "Oman",
        prefix: "+968",
        code: "OM"
    },
    {
        country: "Pakistan",
        prefix: "+92",
        code: "PK"
    },
    {
        country: "Palau",
        prefix: "+680",
        code: "PW"
    },
    {
        country: "Palestinian Territory, Occupied",
        prefix: "+970",
        code: "PS"
    },
    {
        country: "Panama",
        prefix: "+507",
        code: "PA"
    },
    {
        country: "Papua New Guinea",
        prefix: "+675",
        code: "PG"
    },
    {
        country: "Paraguay",
        prefix: "+595",
        code: "PY"
    },
    {
        country: "Peru",
        prefix: "+51",
        code: "PE"
    },
    {
        country: "Philippines",
        prefix: "+63",
        code: "PH"
    },
    {
        country: "Pitcairn",
        prefix: "+872",
        code: "PN"
    },
    {
        country: "Poland",
        prefix: "+48",
        code: "PL"
    },
    {
        country: "Portugal",
        prefix: "+351",
        code: "PT"
    },
    {
        country: "Puerto Rico",
        prefix: "+1939",
        code: "PR"
    },
    {
        country: "Qatar",
        prefix: "+974",
        code: "QA"
    },
    {
        country: "Romania",
        prefix: "+40",
        code: "RO"
    },
    {
        country: "Russia",
        prefix: "+7",
        code: "RU"
    },
    {
        country: "Rwanda",
        prefix: "+250",
        code: "RW"
    },
    {
        country: "Reunion",
        prefix: "+262",
        code: "RE"
    },
    {
        country: "Saint Barthelemy",
        prefix: "+590",
        code: "BL"
    },
    {
        country: "Saint Helena, Ascension and Tristan Da Cunha",
        prefix: "+290",
        code: "SH"
    },
    {
        country: "Saint Kitts and Nevis",
        prefix: "+1869",
        code: "KN"
    },
    {
        country: "Saint Lucia",
        prefix: "+1758",
        code: "LC"
    },
    {
        country: "Saint Martin",
        prefix: "+590",
        code: "MF"
    },
    {
        country: "Saint Pierre and Miquelon",
        prefix: "+508",
        code: "PM"
    },
    {
        country: "Saint Vincent and the Grenadines",
        prefix: "+1784",
        code: "VC"
    },
    {
        country: "Samoa",
        prefix: "+685",
        code: "WS"
    },
    {
        country: "San Marino",
        prefix: "+378",
        code: "SM"
    },
    {
        country: "Sao Tome and Principe",
        prefix: "+239",
        code: "ST"
    },
    {
        country: "Saudi Arabia",
        prefix: "+966",
        code: "SA"
    },
    {
        country: "Senegal",
        prefix: "+221",
        code: "SN"
    },
    {
        country: "Serbia",
        prefix: "+381",
        code: "RS"
    },
    {
        country: "Seychelles",
        prefix: "+248",
        code: "SC"
    },
    {
        country: "Sierra Leone",
        prefix: "+232",
        code: "SL"
    },
    {
        country: "Singapore",
        prefix: "+65",
        code: "SG"
    },
    {
        country: "Slovakia",
        prefix: "+421",
        code: "SK"
    },
    {
        country: "Slovenia",
        prefix: "+386",
        code: "SI"
    },
    {
        country: "Solomon Islands",
        prefix: "+677",
        code: "SB"
    },
    {
        country: "Somalia",
        prefix: "+252",
        code: "SO"
    },
    {
        country: "South Africa",
        prefix: "+27",
        code: "ZA"
    },
    {
        country: "South Sudan",
        prefix: "+211",
        code: "SS"
    },
    {
        country: "South Georgia and the South Sandwich Islands",
        prefix: "+500",
        code: "GS"
    },
    {
        country: "Spain",
        prefix: "+34",
        code: "ES"
    },
    {
        country: "Sri Lanka",
        prefix: "+94",
        code: "LK"
    },
    {
        country: "Sudan",
        prefix: "+249",
        code: "SD"
    },
    {
        country: "Suricountry",
        prefix: "+597",
        code: "SR"
    },
    {
        country: "Svalbard and Jan Mayen",
        prefix: "+47",
        code: "SJ"
    },
    {
        country: "Swaziland",
        prefix: "+268",
        code: "SZ"
    },
    {
        country: "Sweden",
        prefix: "+46",
        code: "SE"
    },
    {
        country: "Switzerland",
        prefix: "+41",
        code: "CH"
    },
    {
        country: "Syrian Arab Republic",
        prefix: "+963",
        code: "SY"
    },
    {
        country: "Taiwan",
        prefix: "+886",
        code: "TW"
    },
    {
        country: "Tajikistan",
        prefix: "+992",
        code: "TJ"
    },
    {
        country: "Tanzania, United Republic of Tanzania",
        prefix: "+255",
        code: "TZ"
    },
    {
        country: "Thailand",
        prefix: "+66",
        code: "TH"
    },
    {
        country: "Timor-Leste",
        prefix: "+670",
        code: "TL"
    },
    {
        country: "Togo",
        prefix: "+228",
        code: "TG"
    },
    {
        country: "Tokelau",
        prefix: "+690",
        code: "TK"
    },
    {
        country: "Tonga",
        prefix: "+676",
        code: "TO"
    },
    {
        country: "Trinidad and Tobago",
        prefix: "+1868",
        code: "TT"
    },
    {
        country: "Tunisia",
        prefix: "+216",
        code: "TN"
    },
    {
        country: "Turkey",
        prefix: "+90",
        code: "TR"
    },
    {
        country: "Turkmenistan",
        prefix: "+993",
        code: "TM"
    },
    {
        country: "Turks and Caicos Islands",
        prefix: "+1649",
        code: "TC"
    },
    {
        country: "Tuvalu",
        prefix: "+688",
        code: "TV"
    },
    {
        country: "Uganda",
        prefix: "+256",
        code: "UG"
    },
    {
        country: "Ukraine",
        prefix: "+380",
        code: "UA"
    },
    {
        country: "United Arab Emirates",
        prefix: "+971",
        code: "AE"
    },
    {
        country: "United Kingdom",
        prefix: "+44",
        code: "GB"
    },
    {
        country: "United States",
        prefix: "+1",
        code: "US"
    },
    {
        country: "Uruguay",
        prefix: "+598",
        code: "UY"
    },
    {
        country: "Uzbekistan",
        prefix: "+998",
        code: "UZ"
    },
    {
        country: "Vanuatu",
        prefix: "+678",
        code: "VU"
    },
    {
        country: "Venezuela, Bolivarian Republic of Venezuela",
        prefix: "+58",
        code: "VE"
    },
    {
        country: "Vietnam",
        prefix: "+84",
        code: "VN"
    },
    {
        country: "Virgin Islands, British",
        prefix: "+1284",
        code: "VG"
    },
    {
        country: "Virgin Islands, U.S.",
        prefix: "+1340",
        code: "VI"
    },
    {
        country: "Wallis and Futuna",
        prefix: "+681",
        code: "WF"
    },
    {
        country: "Yemen",
        prefix: "+967",
        code: "YE"
    },
    {
        country: "Zambia",
        prefix: "+260",
        code: "ZM"
    },
    {
        country: "Zimbabwe",
        prefix: "+263",
        code: "ZW"
    }

];
