import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import client from "../client";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

export default function useOffer(offer_id: string | undefined, isSummary: boolean = true) {
    const navigate = useNavigate();
    const { can } = useAuthContext();

    // context
    const { setAdditionalFreightCost, setManualFreightCost, setManualShippingMethod, setRothoNotes, setDeliveryRestriction } = useShippingContext();

    const fetchOffer = async (offer_id: string | undefined) => {
        const res = await client.get(`/offers/${offer_id}`);
        return res.data;
    };

    // GET SINGLE OFFER REQUEST
    const { data, isLoading, isFetching } = useQuery(["offer", offer_id],
        () => fetchOffer(offer_id),
        {
            refetchOnWindowFocus: false,
            enabled: !!offer_id,
            onSuccess: (data: any) => {
                // SET DELIVERY RESTRICTION
                if (can(["can_see_availability"])) {
                    setDeliveryRestriction(data?.data?.deliveryRestriction ?? 1);
                } else {
                    // set partial delivery if the user cannot se availability - issue #397
                    setDeliveryRestriction(1);
                }

                // SET ROTHO NOTES
                if (data?.data?.note) {
                    setRothoNotes(data?.data?.note);
                } else {
                    setRothoNotes("");
                }

                // SET ADDITIONAL/MANUAL FREIGHT COSTS
                if (data?.data?.shipping_info?.additional_freight_cost) {
                    setAdditionalFreightCost(data?.data?.shipping_info?.additional_freight_cost);
                }
                if (data?.data?.shipping_info?.manual_freight_cost) {
                    setManualFreightCost(data?.data?.shipping_info?.manual_freight_cost);
                }
                if (data?.data?.shipping_info?.manual_shipping_method) {
                    setManualShippingMethod(data?.data?.shipping_info?.manual_shipping_method);
                }

                // SET CUSTOMER
                const customer = data?.data?.customer;

                if (customer) {
                    setSelectedCustomer({ ...customer, label: `${customer.name} - ${customer.code} - ${customer.ts_fullname}` });
                } else {
                    setSelectedCustomer(null);
                }
            },
            onError: () => {
                navigate("/carts");
            }
        }
    );

    // customer state
    const [selectedCustomer, setSelectedCustomer] = useState(data?.data?.customer || null);

    // GET OFFER SUMMARY INFO
    const { data: summary, isLoading: summaryLoading, isFetching: summaryFecthing } = useQuery(["summary"],
        () => getSummaryInfo(),
        {
            refetchOnWindowFocus: false,
            enabled: !!data && !data?.data?.isCustomerProposal && isSummary
        }
    );

    const getSummaryInfo = async () => {
        const res = await client.get(`/offers/${offer_id}/summary`);
        return res.data;
    };

    return { data, summary, selectedCustomer, setSelectedCustomer, isLoading, summaryLoading, isFetching, summaryFecthing };
}
