import React from "react";
import ReactDOM from "react-dom";
import {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";

// sentry
import * as Sentry from "@sentry/react";

// styles
import "./styles.css";
import "react-toastify/dist/ReactToastify.css";

// i18n config
import "./i18next/i18n";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_SENRTY_URI) {
    const isProduction: boolean = ["production"].indexOf(process.env.REACT_APP_ENV || "") >= 0;
    Sentry.init({
        dsn: process.env.REACT_APP_SENRTY_URI,
        beforeSend(event, hint: any) {
            const error = hint.originalException;
            if (error && error.message) {
                let msg = error.message.toLowerCase();
                if (msg.includes('status code 404')
                    || msg.includes('status code 403')
                    || msg.includes('status code 401')
                    || msg.includes('failed to fetch')
                    || msg.includes('timeout exceeded')
                    || msg.includes('request aborted')
                    || msg.includes('the operation was aborted')
                ) {
                    return null;
                }
            }
            return event;
        },
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                // Dev only
                tracePropagationTargets: ["localhost", /^https:\/\/api\.eservice-test\.rothoblaas\.com\/api/],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: isProduction ? 0.1 : 0.5, // Capture 50% of the transactions
        // Session Replay
        replaysSessionSampleRate: isProduction ? 0.1 : 0.5, // This sets the sample rate at 10%. You may want to change it to 50% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: isProduction ? 0.1 : 0.5, // If you're not already sampling the entire session, change the sample rate to 50% when sampling sessions where errors occur.
    });
}

ReactDOM.render(
    <React.StrictMode>
        {/* <HistoryRouter history={history}> */}
        <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Suspense>
        {/* </HistoryRouter> */}
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
