export const useSelectStyles = () => {
    const CustomStyles = {
        container: (provided: any, state: any) => ({
            ...provided,
            pointerEvents: "auto",
            cursor: `${state.isDisabled ? "not-allowed" : null}`,
            fontWeight: 300
        }),
        control: (_: any, state: any) => ({
            border: `${state.isFocused ? "2px solid #035ECC" : "1px solid rgb(229 231 235)"}`,
            borderRadius: `${state.isFocused ? "3px" : null}`,
            display: "flex",
            justifyContent: "space-between",
            height: "42px",
            backgroundColor: `${state.isDisabled ? "#fafafa" : "#FFF"}`,
        }),
        SelectContainer: () => ({
            border: "1px solid rgb(229 231 235)",
            display: "flex",
            justifyContent: "space-between",
            padding: "3px 0px",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            padding: "0px 0.4rem",
            textOverflow: "ellipsis",
            maxWidth: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#000"
        }),
        singleValue: () => ({
            display: "flex",
            alignItems: "center",
            marginLeft: "3px",
        }),
        placeholder: (_: any, state: any) => ({
            display: `${state.isFocused && "none"}`,
            marginLeft: "3px",
            color: `${state.isDisabled ? "#A7A7A7" : "#9CA3AF"}`,
            fontSize: "14px",
            fontWeight: 300
        }),
        menu: (provided: any) => ({
            ...provided,
            borderRadius: "0px",
            padding: "5px"
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: "#202020",
            // color: state.isSelected ? 'white' : '#202020',
            display: "flex",
            padding: "5px 5px",
            backgroundColor: state.isDisabled
                ? undefined
                // : state.isSelected
                //     ? 'black'
                : state.isFocused
                    ? "#E5E7EB"
                    : state.isActive ?
                        "#202020" : undefined,
            opacity: state.isDisabled ? 0.3 : 1,
            cursor: state.isDisabled ? "not-allowed" : "pointer",
        }),
        loadingMessage: (provided: any) => ({
            ...provided,
            color: "#202020"
        }),
        noOptionsMessage: (provided: any) => ({
            ...provided,
            color: "#202020"
        }),
        clearIndicator: (provided: any) => ({
            ...provided,
            cursor: "pointer",
            color: "black"
        })
    };

    return { CustomStyles };
};
