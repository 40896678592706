import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { Item } from "../../interfaces/Item";
import { EservicesStatus } from "../../constants/EservicesStatus";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Status, StatusVariant } from "../Status";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { Tooltip } from "@reach/tooltip";

type PreviewCartItemsTableProps = {
    data: any;
    hiddenColumns: string[];
};

export default function PreviewCartItemsTable({ data: propsData, hiddenColumns }: PreviewCartItemsTableProps) {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    // table data
    const data = useMemo(
        () => {
            return propsData?.items.filter((item: any) => { return !user?.constants?.extra_services?.includes(item?.code) && !user?.constants?.is_freight_cost?.includes(item?.code); });
        },
        [propsData?.items]
    );

    const columns: Array<Column<Item>> = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                disableSortBy: true,
                className: "text-left p-3",
                Cell: (props: any) => {
                    return <span>{props.cell.row.original.code?.toUpperCase()}</span>;
                }
            },
            {
                Header: t("Descrizione").toString(),
                accessor: "description",
                disableSortBy: true,
                className: "text-left p-3",
            },
            {
                Header: t("Quantità").toString(),
                accessor: "qty",
                disableSortBy: true,
                className: "text-left p-3",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(propsData?.customer?.language_id);

                    return (
                        <>
                            {numberFormat.format(props.row.original.qty)}
                        </>
                    );
                }
            },
            {
                Header: t("Magazzino").toString(),
                accessor: "warehouse",
                className: "text-left p-4",
                cellClasses: "text-left p-4",
                Cell: (props: any) => {
                    const storagearea_id = props?.row?.original?.storageArea?.code;

                    if (!storagearea_id) return <span>-</span>

                    return <Tooltip
                        label={props?.row?.original?.storageArea?.description ?? props?.cart?.data?.customer?.technicalSale?.storageArea?.description}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            fontWeight: "300",
                            marginRight: "20px",
                            zIndex: 50,
                        }}
                    >
                        <span className="font-light cursor-default">{storagearea_id}</span>
                    </Tooltip>
                },
            },
            {
                Header: t("Sconto 1 (%)").toString(),
                accessor: "discount_1",
                disableSortBy: true,
                className: "text-right p-3",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(propsData?.customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return (
                        <>
                            {numberFormat.format(props.row.original.discount_1)}
                        </>
                    );
                }
            },
            {
                Header: t("Sconto 2 (%)").toString(),
                accessor: "discount_2",
                disableSortBy: true,
                className: "text-right p-3",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(propsData?.customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return (
                        <>
                            {numberFormat.format(props.row.original.discount_2)}
                        </>
                    );
                }
            },
            {
                Header: t("Prezzo listino cliente ({{currency}})", { currency: propsData?.customer?.currency }).toString(),
                accessor: "pricevalue",
                disableSortBy: true,
                className: "text-right p-3",
                Cell: (props: any) => {
                    const price = props.cell.row.original.pricevalue;

                    const numberFormat = new Intl.NumberFormat(propsData?.customer?.language_id, { minimumFractionDigits: 5, maximumFractionDigits: 5 });

                    return numberFormat.format(price);
                }
            },
            {
                Header: t("Prezzo scontato cliente unitario ({{currency}})", { currency: propsData?.customer?.currency }).toString(),
                accessor: "net_price",
                disableSortBy: true,
                className: "text-right p-3",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(propsData?.customer?.language_id, { minimumFractionDigits: 5, maximumFractionDigits: 5 });

                    return (
                        <>
                            {numberFormat.format(props.row.original.net_price)}
                        </>
                    );
                }
            },
            {
                Header: t("Totale scontato ({{currency}})", { currency: propsData?.customer?.currency }).toString(),
                accessor: "totale_vendita",
                disableSortBy: true,
                className: "text-right p-3",
                Cell: (props: any) => {
                    const qty = props.row.original.qty;
                    const net_price = props.row.original.net_price;

                    const total = qty * net_price;
                    const numberFormat = new Intl.NumberFormat(propsData?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(propsData?.customer?.currency), maximumFractionDigits: getCurrencyDecimals(propsData?.customer?.currency) });

                    return <>{numberFormat.format(total)}</>;
                },
            },
            {
                Header: t("Stato").toString(),
                accessor: "status",
                className: "p-3",
                id: "status",
                Cell: (props: any) => {
                    const orderStatus = props.row.original.status;
                    const status = EservicesStatus.order.find((status) => status.status === orderStatus);

                    const statusVariant = status?.variant as keyof typeof StatusVariant;

                    return <Status variant={Status.variant[statusVariant]}>{t(status?.description ?? "")}</Status>;
                }
            },
        ],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({ columns, data, initialState: { pageSize: 100, hiddenColumns: hiddenColumns } }, useSortBy, usePagination);

    return (
        <div className='border'>
            <table {...getTableProps()} className="w-full">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className='odd:bg-tertiary'>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
