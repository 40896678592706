import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import SummaryTable from "./SummaryTable";
import { Section } from "../Section";

type ProductsSummaryProps = {
    data: any,
    summary?: any
    availabitiliy?: boolean
};

export default function ProductsSummary({ data, summary, availabitiliy = false }: ProductsSummaryProps) {
    const { user, roles, can } = useAuthContext();

    // state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (parseInt(data.data.kind_of_pricing) !== 1 && roles(user?.constants?.external_roles)) {
            setHiddenColumns(prevValues => [...prevValues, "discount_1", "discount_2", "pricevalue"]);
        }

        if (!data?.data?.number) {
            setHiddenColumns(prevValues => [...prevValues, "status"]);
        }

        if (!availabitiliy) {
            setHiddenColumns(prevValues => [...prevValues, "availability"]);
        }

        if (roles(user?.constants?.external_roles) || !data?.data?.number) {
            setHiddenColumns(prevValues => [...prevValues, "reservedqty"]);
        }

        if (!can(["can_see_prices"])) {
            setHiddenColumns(prevValues => [...prevValues, "discount_2", "pricevalue", "net_price", "discounted_total"]);
        }

        if (!can(["can_see_availability"])) {
            setHiddenColumns((prevValues) => { return [...prevValues, "availability", "warehouse"]; });
        }
    }, [data]);

    return (
        <Section title={"Elenco prodotti"} className={"mb-20"}>
            {
                data &&
                <SummaryTable
                    customer={data?.data?.customer}
                    cart={data}
                    items={data?.data?.items}
                    total_products={summary ? summary?.total_products_without_iva : data?.data?.summary?.total_products_without_iva}
                    hiddenColumns={hiddenColumns}
                />
            }
        </Section>
    );
}
