import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowRepeat } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { Item } from "../../../../interfaces/Item";
import { Button } from "../../../Button";
import ChangeProductModal from "./ChangeProductModal";
import { useCartItemsMutations } from "../../../../api/carts/useCartItemsMutations";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";
import { useNavigate } from "react-router-dom";
import useAlternativeProducts from "../../../../api/alternative-products/useAlternativeProducts";
import { Tooltip } from "@reach/tooltip";

type AvailableItemsTableProps = {
    items: Item[],
    cart: any,
};

export const ChangeCell = (props: any) => {
    const { t } = useTranslation();
    const { cart, row } = props;

    // state
    const [open, setOpen] = useState<any>(false);

    // react query
    const { data: alternativeItems } = useAlternativeProducts({ product_id: row.original.code, "filter[customer_code]": cart?.data?.customer?.code }, open);

    return (
        <>
            {
                open &&
                <ChangeProductModal
                    open={open}
                    onClose={setOpen}
                    alternativeItems={alternativeItems?.data}
                    {...props}
                />
            }
            {
                row.original.has_alternative_item ?
                    <div className='flex items-center gap-x-1'>
                        <Button variant={Button.variant.text} type="button" onClick={() => setOpen(true)}>{t("Sostituisci articolo")}</Button>
                        <BsArrowRepeat className='font-normal text-sm' />
                    </div>
                    : null
            }
        </>
    );
};

const UnavailableItemsTable: React.FC<AvailableItemsTableProps> = ({ items, cart }) => {
    const { t } = useTranslation();
    const { roles } = useAuthContext();
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    // react query
    const { deleteProduct, updateProduct } = useCartItemsMutations();

    const handleRemoveItem = useCallback(async (item: any) => {
        const params = {
            cart_id: cart?.data?.id,
            product_id: item.id,
            body: roles(user?.constants?.external_roles) ? { quantity: item.rowQty - item.qty } : { quantity: item.rowQty - item.qty, discount_1: item.discount_1, discount_2: item.discount_2 }
        };

        // olny splitted items have rowQty property
        if (item.rowQty) {
            if (item.rowQty === item.qty) {
                await deleteProduct.mutateAsync({ cart_id: cart?.data?.id, product_id: item.id });
            } else {
                await updateProduct.mutateAsync(params);
            }
        }
    }, [cart?.data?.id]);

    useEffect(() => {
        if (cart?.data?.items.length <= 0) {
            navigate(`/carts/${cart?.data?.id}`);
        }
    }, [cart]);

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<Item>> = useMemo(
        () => [
            {
                accessor: "code",
                width: 100,
                minWidth: 100,
                Cell: (props) => {
                    return (
                        <>
                            <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                        </>
                    );
                }
            },
            {
                accessor: "description",
                width: 250,
                minWidth: 250,
                Cell: (props) => {
                    return (
                        <>
                            <div className='font-bold mb-1'>{props.cell.row.original.code.toUpperCase()}</div>
                            <span>{props.cell.row.original.description}</span>
                        </>
                    );
                }
            },
            {
                accessor: "qty",
                width: 250,
                minWidth: 250,
                Cell: (props) => {
                    const qty = props.row.original.qty;

                    const numberFormat = new Intl.NumberFormat(user?.language_id);

                    return (
                        <div className="flex flex-col gap-1">
                            <span className="font-normal">{t("Quantità")}</span>
                            <span>{numberFormat.format(qty) + " " + t("Pezzi")}</span>
                        </div>
                    );
                }
            },
            {
                id: "storageArea",
                width: 150,
                minWidth: 150,
                Cell: (props: any) => {
                    return (
                        <div className="flex flex-col gap-1">
                            <span className="font-normal">{t("Magazzino")}</span>
                            <Tooltip
                                label={props?.row?.original?.storageArea?.description ?? props?.cart?.data?.customer?.technicalSale?.storageArea?.description}
                                style={{
                                    backgroundColor: "#F1F5F6",
                                    borderRadius: "3px",
                                    fontWeight: "300",
                                    marginRight: "20px",
                                    zIndex: 50
                                }}
                            >
                                <span className="cursor-default">{props?.row?.original?.storageArea?.code ?? props?.cart?.data?.customer?.technicalSale?.storageArea?.code}</span>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                id: "disponibilità",
                Cell: (props: any) => {
                    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

                    const availableQuantity = props.row.original.availabilityInfo?.availableQuantity;
                    const availabilityCoverDate = props.row.original.availabilityInfo?.availabilityCoverDate;

                    const isNotAvailable = props.row.original.rowQty > availableQuantity;

                    return (
                        <div className='flex'>
                            {props.row.original.material_type === 5 && isNotAvailable ? (
                                roles(user?.constants?.external_roles) ? (
                                    <div className='text-rose-500'>{t("contattare TS per la disponibilità")}</div>
                                ) : (
                                    <div className='text-rose-500'>{t("contattare CS per la disponibilità")}</div>
                                )
                            ) : (
                                <>
                                    {
                                        availabilityCoverDate ?
                                            <div className="flex flex-col gap-1">
                                                <span className="font-normal">{t("Disponibilità in magazzino Rothoblaas")}</span>
                                                <span>{dateFormat.format(new Date(availabilityCoverDate))}</span>
                                            </div>
                                            :
                                            <span>{t("In approvvigionamento")}</span>
                                    }
                                </>
                            )}
                        </div>
                    );
                }
            },
            {
                id: "sostituisci",
                Cell: ChangeCell
            },
            {
                id: "elimina",
                Cell: (props: any) => {
                    const item = props.row.original;

                    return (
                        <>
                            <FaTrash
                                className="cursor-pointer hover:text-secondary"
                                onClick={(() => handleRemoveItem(item))}
                            />
                        </>
                    );
                }
            },
        ],
        [t, hiddenColumns]
    );

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({ columns, data, defaultColumn, initialState: { pageSize: 100, hiddenColumns: hiddenColumns }, cart }, useSortBy, usePagination);

    return (
        <div>
            {/* UNAVAILABLE */}
            <div className="flex flex-col justify-center gap-x-2 px-6 py-3 bg-neutral-weaker border">
                <div className="flex gap-3 items-center">
                    <div className="bg-red-500 w-2 h-2 rounded-full" />
                    <span className="text-sm">{t("Non disponibili")}</span>
                </div>
            </div>
            <div className='overflow-y-auto overflow-visible min-h-min max-h-[220px] border-l border-r border-b'>
                <table {...getTableProps()} className="w-full text-xs font-light">
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="border-b last:border-b-0">
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps({ className: (cell.column as any).className, style: { minWidth: cell.column.minWidth, width: cell.column.width } })} className="p-4">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UnavailableItemsTable;
