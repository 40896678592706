import { useTranslation } from "react-i18next";
import { getCurrencyDecimals } from "../../../utils/getCurrencyDecimals";
import { Title } from "../../Title";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";

type ExtraServicesSummaryProps = {
    data: any
};

export default function ExtraServicesSummary({ data }: ExtraServicesSummaryProps) {
    const { t } = useTranslation();
    const { user, can } = useAuthContext();

    const currency = data?.data?.isCustomerProposal ? data?.data?.currency_id : data?.data?.customer?.currency;
    const numberFormat = currency ? new Intl.NumberFormat(data?.data?.customer?.language_id, { style: "currency", currency: currency, minimumFractionDigits: getCurrencyDecimals(currency), maximumFractionDigits: getCurrencyDecimals(currency) }) : null;

    return (
        <>
            {/* servizi accessori */}
            {
                data?.data?.items?.some((item: any) => {
                    return user?.constants?.active_extra_services?.includes(item.code);
                }) > 0 &&
                <div className="border p-6 mb-8 space-y-3">
                    <Title variant={Title.variant.secondary}>{t("Servizi accessori")}</Title>

                    {
                        data?.data?.items?.filter((item: any) => {
                            return user?.constants?.active_extra_services?.includes(item.code);
                        }).map((extra_service: any) => {
                            return (
                                <div className="flex justify-between text-sm font-light" key={extra_service?.id}>
                                    <span>
                                        {t(extra_service?.code ?? "")}
                                    </span>
                                    {
                                        can(["can_see_prices"]) &&
                                        <span className="pr-3 font-normal">
                                            {`${numberFormat?.format(extra_service?.net_price)}`}
                                        </span>
                                    }
                                </div>
                            );
                        }
                        )
                    }

                    {
                        data?.data?.items?.find((extra_service: any) => {
                            return "TELEPHONE" === extra_service.code;
                        }) &&
                        <div className="text-xs space-y-3 font-light">
                            <div>
                                <span>{t("Referente consegna:")} </span>
                                <span className="mr-4 font-normal">{data?.data?.shipping_info?.delivery_notice_name}</span>
                                <span>{t("Telefono referente:")} </span>
                                <span className="font-normal">{data?.data?.shipping_info?.delivery_notice_phone}</span>
                            </div>
                            {
                                data?.data?.shipping_info?.delivery_notice_note &&
                                <div>
                                    <span>{t("Note consegna")}:</span>
                                    <span className="font-normal">{data?.data?.shipping_info?.delivery_notice_note}</span>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </>
    );
}
