export const RolesGroups =   [
    {
        title: 'Ruoli Amministrativi e Direzionali',
        roles: [
            'admin',
            'CS',
            'CS_Other',
            'BA',
            'DIR',
            'Api_Key'
        ]
    },
    {
        title: 'Ruoli Operativi e Funzionali',
        roles: [
            'Assistant',
            'Credit_Management',
            'Other_Departments',
            'Marketing',
            'Officina'
        ]
    },
    {
        title: 'Ruoli Tecnici e Commerciali',
        roles: [
            'TS',
            'ASM',
            'RSM',
            'DSM',
            'Margin_Calculator'
        ]
    },
    {
        title: 'Ruoli Clienti',
        roles: [
            'CLIENT',
            'CLIENT_LIMITED',
            'CLIENT_NO_AVAILABILITY'
        ]
    }
];

export const PermissionsGroups = [
    {
        title: 'Ordini e Offerte',
        permissions: [
            'can_make_order',
            'can_make_offer',
            'can_ask_offer'
        ]
    },
    {
        title: 'Visibilità Prezzi e Disponibilità',
        permissions: [
            'can_see_prices',
            'can_see_availability'
        ]
    },
    {
        title: 'Modifica Ordini',
        permissions: [
            'can_modify_payment_conditions',
            'can_modify_payment_conditions_it',
            'can_modify_any_payment_conditions',
            'can_delete_orders',
            'can_set_order_classification'
        ]
    },
    {
        title: 'Elenco clienti',
        permissions: [
            'can_see_client_list',
            'can_see_full_client_list'
        ]
    },
    {
        title: 'Profilo e Anagrafica',
        permissions: [
            'can_see_profile_data',
            'can_see_administrative_data',
            'can_see_billing_data',
            'can_see_logistic_data',
            'can_see_contacts_data',
            'can_see_financial_data'
        ]
    },
    {
        title: 'Liste',
        permissions: [
            'can_see_orders_list',
            'can_see_outstanding_orders',
            'can_see_price_history',
            'can_see_offers_list',
            'can_see_invoices_list',
            'can_see_shipments_list'
        ]
    },
    {
        title: 'Condizioni Riservate',
        permissions: [
            'can_see_discounted_pricelist',
            'can_see_gross_pricelist',
            'can_see_marketing_pricelist',
            'can_manage_discount_table'
        ]
    },
    {
        title: 'Assistenza',
        permissions: [
            'can_see_complaints_list',
            'can_make_complaints',
            'can_see_private_complaint_files'
        ]
    },
    {
        title: 'Gestione Utenti',
        permissions: [
            'can_manage_employees',
            'can_manage_clients'
        ]
    },
    {
        title: 'Altri',
        permissions: [
            'can_see_margin_calculator',
            'can_generate_masterfile',
            'can_see_debug_data'
        ]
    }
];;
