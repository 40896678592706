import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";

const MAX_ITEMS = 100;

export const productsListImport = (file: File, container: any, t: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onerror = ((error) => {
            reject(error);
        });

        fileReader.onload = (e) => {
            // try {
            const bufferArray = e?.target?.result;

            const wb = XLSX.read(bufferArray, {type: "buffer", cellDates: true});

            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            // console.log(XLSX.utils.sheet_to_json(ws, {raw: false, dateNF:"#,##0.00"}));

            let new_range;

            // controllo la cella D1, se NON è compilato siamo nella casistica import di un file esportato
            // verifico anche che ci sia la cella E9 (intestazione) altrimenti potrebbe essere la casistica cliente che non ha la E9
            if (!ws.D1 && ws.E9) {
                const wbSheetRange = ws["!ref"] ? ws["!ref"] : null;

                if (!wbSheetRange) {
                    reject(t("File non valido"));
                    return;
                }

                const range = XLSX.utils.decode_range(wbSheetRange);
                range.s.c = 0;
                range.s.r = 8;
                new_range = XLSX.utils.encode_range(range);
                // console.log(range);
                // console.log(new_range);

                // questa non è una stringa
                // è un riferimento nascosto che viene assegnato alla cella per il riconoscimento
                if (ws.D9.v === "PREZZO SCONTATO CLIENTE UNITARIO (EUR)") {
                    ws.A9.w = "code";
                    ws.C9.w = "qty";
                    ws.D9.w = "net_price";
                } else {
                    ws.A9.w = "code";
                    ws.C9.w = "qty";
                    ws.D9.w = "discount";
                    ws.G9.w = "net_price";
                }

                const maxItem: any = new_range.match(/[0-9]{1,3}/g);
                const totItem: number = parseInt(JSON.stringify(maxItem.slice(1)).replace(/(\[")|("\])/g, ""));

                for (let i = 10; i <= totItem; i++) {
                    // console.log("gg");

                    if (ws["D" + i] !== undefined && ws["D" + i].t === "d") {
                        reject(t("Errore in riga {{row}}. Controllare il formato del dato", {row: i}));
                        return;
                    }
                }


                const data: any = XLSX.utils.sheet_to_json(ws, {range: new_range});

                if (data.length) {
                    if (!data[data.length - 1].qty && !data[data.length - 1].discount && !data[data.length - 1].net_price) {
                        data.splice(data.length - 1, 1);
                    }
                }

                const mappedData = data.map((item: any) => {
                    return {...item, row: item.__rowNum__};
                });

                const freeSpace = MAX_ITEMS - container.length;
                if (data.length > freeSpace) {
                    reject(t("Limite massimo di {{max_items}} articoli superato", {max_items: MAX_ITEMS}));
                    return;
                }

                resolve(mappedData);
            } else {

                // per identificare il file cliente controllo che le celle A1 e B1 siano compilate, la C1 e D1 no
                if (!ws.A1 || !ws.B1) {
                    reject(t("File non valido"));
                    return;
                }

                ws.A1.w = "code";
                ws.B1.w = "qty";
                if (ws.C1) {
                    ws.C1.w = "discount";
                }
                if (ws.D1) {
                    ws.D1.w = "net_price";
                }

                const maxItem: number = XLSX.utils.sheet_to_json(ws, {raw: true}).length + 1;

                // cliente non ha discount e net_price
                if (ws.C1) {
                    for (let i = 0; i <= maxItem; i++) {
                        if (ws["C" + i] !== undefined && ws["C" + i].t === "d") {
                            reject(t("Errore in riga {{row}}. Controllare il formato del dato", {row: i}));
                            return;
                        }
                    }
                }

                const data: any = XLSX.utils.sheet_to_json(ws, {/*range: new_range*/});

                if (data.length) {
                    if (!data[data.length - 1].qty && !data[data.length - 1].discount && !data[data.length - 1].net_price) {
                        data.splice(data.length - 1, 1);
                    }
                }

                const mappedData = data.map((item: any) => {
                    return {...item, row: item.__rowNum__};
                });

                const freeSpace = MAX_ITEMS - container.length;
                if (data.length > freeSpace) {
                    reject(t("Limite massimo di {{max_items}} articoli superato", {max_items: MAX_ITEMS}));
                    return;
                }

                resolve(mappedData);
            }
        };
    });
};
