import { useEffect, useState } from "react";
import PreviewShippingItemsTable from "../shippings/PreviewShippingItemsTable";
import PreviewCartItemsTable from "./PreviewCartItemsTable";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import PreviewSemiramisComplaintsTable from "../assistance/semiramis-complaints/PreviewSemiramisComplaintsTable";

// SUB ROW TEMPLATE COMPONENT
const SubRows = ({ data, loading, endpoint }: any) => {
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const { user, roles, can } = useAuthContext();

    useEffect(() => {
        const tempHiddenColumn = [];
        if (!data?.number) {
            tempHiddenColumn.push("status");
        }

        if (["invoices"].includes(endpoint)) {
            tempHiddenColumn.push("warehouse");
        }

        // columns "discount_1", "discount_2", "pricevalue" must be hidden only for some endpoints that use PreviewCartItemsTable for now only cart & offers (invoices should have its own permission so who sees an invoice sees the prices too)
        if (["carts", "offers", "orders"].includes(endpoint) && parseInt(data?.kind_of_pricing) !== 1 && roles(user?.constants?.external_roles)) {
            tempHiddenColumn.push("discount_1", "discount_2", "pricevalue");
        }

        if(!can(["can_see_prices"])){
            tempHiddenColumn.push("discount_1", "discount_2", "pricevalue", "net_price", "totale_vendita");
        }

        if (!can(["can_see_availability"])) {
            tempHiddenColumn.push("availability", "warehouse");
        }

        setHiddenColumns(tempHiddenColumn);
    }, [data]);

    if (loading) {
        return (
            <span>
                <div>
                    Loading...
                </div>
            </span>
        );
    }

    if (endpoint === "semiramis-complaints") {
        return <PreviewSemiramisComplaintsTable data={data} />;

    }

    // return diferent preview table for shippings
    if (endpoint === "shipping/shippings") {
        return <PreviewShippingItemsTable data={data} hiddenColumns={can(["can_see_availability"]) ? [] : ["warehouse"]} />;
    }

    // return preview table for order/offers/drafts
    return <PreviewCartItemsTable data={data} hiddenColumns={hiddenColumns} />;
};

export default SubRows;
