import { useTranslation } from "react-i18next";
import OrdersSearchBar from "../components/orders/OrdersSearchBar";
import { OrdersTable } from "../components/orders/OrdersTable";
import { useCallback, useEffect, useState } from "react";
import { ImFilesEmpty } from "react-icons/im";
import { Loader } from "../components/Loader";
import Pagination from "../components/Pagination";
import useOrders from "../api/orders/useOrders";
import SubRowAsync from "../components/carts/SubRowAsync";
import { useSearchParams } from "react-router-dom";
import { Section } from "../components/Section";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { Slide, toast, ToastContainer } from "react-toastify";

const Orders = ({ setTitle }: any) => {
    const { t } = useTranslation();
    const { roles } = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();

    // state
    const [canFetch, setCanFetch] = useState<boolean>(false);

    // react query
    const { orders, isLoading, isFetching, isError } = useOrders(Object.fromEntries(searchParams.entries()), canFetch);

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint="orders" />
        ),
        []
    );

    const handlePageChange = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (searchParams.has("page")) {
            setCanFetch(true);
        }
    }, []);

    useEffect(() => {
        if (roles(["CLIENT", "CLIENT_LIMITED", "CLIENT_NO_AVAILABILITY", "TS"])) {
            setSearchParams({ page: "1", include: "blocks" });
            setCanFetch(true);
        }
    }, []);

    useEffect(() => {
        if (isError) {
            toast.error(t("Verifica che i dati inseriti nella form siano corretti"));
        }
    }, [isError]);

    useEffect(() => {
        setTitle(t("I miei ordini"));
    }, []);

    return (
        <div>
            {(isLoading || isFetching) && canFetch && <Loader />}

            <Section title={"Cerca ordine"} className="mb-10">
                <OrdersSearchBar setSearchParams={setSearchParams} setCanFetch={setCanFetch} />
            </Section>

            <>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
                {
                    orders?.data?.length > 0 ?
                        <>
                            <OrdersTable
                                items={orders?.data}
                                setSearchParams={setSearchParams}
                                renderRowSubComponent={renderRowSubComponent}
                            />
                            <Pagination
                                currentPage={parseInt(searchParams.get("page") ?? "1", 10)}
                                totalCount={orders?.meta.total}
                                pageSize={10}
                                onPageChange={handlePageChange}
                            />
                        </>
                        :
                        <div className="h-96 flex flex-col items-center justify-center space-y-6">
                            <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                            {canFetch ? (
                                <p className="text-2xl text-gray-600">{t("Nessun ordine trovato")}</p>
                            ) : (
                                <p className="text-2xl text-gray-600">{t("Imposta un filtro per effettuare una ricerca.")}</p>
                            )}
                        </div>
                }
            </>
        </div>
    );
};

export default Orders;
