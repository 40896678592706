import { Dialog } from "@reach/dialog";
import { ReactNode } from "react";
import { MdClose } from "react-icons/md";
import { Title } from "../Title";

type Props = {
    open: boolean
    onClose?: (arg: any) => void
    children: ReactNode
    title?: string
};

export const Modal = ({ open, children, onClose, title }: Props) => {
    const closeModal = () => {
        if (onClose) {
            onClose(false);
        }
    };

    return (
        <Dialog isOpen={open} onDismiss={closeModal} className="overflow-y-auto md:overflow-y-visible !h-auto !w-auto absolute inset-0 md:bottom-auto md:left-1/2 md:-translate-x-1/2 z-50 modal-selector md:!my-20 !m-0 !p-8">
            <div className="space-y-4">
                <div className="relative">
                    <Title variant={Title.variant.secondary}>{title}</Title>
                    {!!onClose && <MdClose className="absolute scale-150 top-1.5 right-0 cursor-pointer hover:text-secondary" onClick={closeModal} />}
                </div>
                <div className="font-light text-sm">
                    {children}
                </div>
            </div>
        </Dialog>
    );
};
