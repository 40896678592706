import {useEffect} from "react";
import TagManager from "react-gtm-module";
import {useLocation} from "react-router-dom";
import {useAuthContext} from "../hooks/use-context/useAuthContext";

export default function GtmDataLayer() {
    const {user, roles} = useAuthContext();
    const {pathname} = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_GTM_ID) {
            const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GTM_ID
            };
            TagManager.initialize(tagManagerArgs);
        }
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_GTM_ID) {
            try {
                const tagManagerArgs = {
                    dataLayer: {
                        userId: user?.id || 0,
                        event: "pageview",
                        user: user ? (roles(user?.constants?.external_roles) ? "customer" : "agent") : "anonymous",
                        page: pathname,
                    },
                    dataLayerName: 'PageDataLayer'
                }
                TagManager.dataLayer(tagManagerArgs);
            } catch (e) {
                console.error(e)
            }
        }
    }, [pathname]);


    return null;
}
