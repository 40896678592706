import client from "../client";
import { useQuery } from "@tanstack/react-query";

type useAlternativeWarehouseParams = {
    cartId: string,
    cartItem: string,
};

export default function useAlternativeWarehouse(endpoint: string) {

    const fetchAlternativeWarehouse = async (endpoint: string) => {
        const res = await client.get(endpoint);
        return res.data;
    };

    const { data, isLoading, isFetching } = useQuery({
        queryKey: ['alternativeWarehouse', endpoint],
        queryFn: () => fetchAlternativeWarehouse(endpoint),
        refetchOnWindowFocus: false
    })

    return { data, isLoading, isFetching };
}
