import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Column, usePagination, useTable } from "react-table";

enum Variants {
    expandable,
}

type TableProps = {
    data: any;
    columns: any;
    label: string;
    emptyStateMessage?: string;
    variants?: Variants;
};

const Table = ({ data, columns, label, variants, emptyStateMessage }: TableProps) => {
    const { t } = useTranslation();

    // state
    const [open, setOpen] = useState(false);

    // TABLE DATA
    const tableData = useMemo(() => {
        return data;
    }, [data]);

    const tableColumns: Array<Column<any>> = useMemo(
        () => columns,
        [data, columns]
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
        useTable({ columns: tableColumns, data: tableData }, usePagination);

    return (
        <>
            {variants === Variants.expandable ? (
                <div className="p-4 border bg-tertiary overflow-auto">
                    <div
                        className="flex items-center cursor-pointer font-normal pb-1"
                        onClick={() => setOpen(!open)}
                    >
                        {!open ? <BiChevronDown className="text-xl" /> : <BiChevronUp className="text-xl" />}
                        {t(label)}
                    </div>

                    {
                        open &&
                        <>
                            {
                                data?.length > 0 ?
                                    <table
                                        {...getTableProps()}
                                        className="w-full font-light border block lg:table bg-white"
                                    >
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th
                                                            {...column.getHeaderProps({
                                                                className: (column as any)
                                                                    .className,
                                                            })}
                                                        >
                                                            {column.render("Header")}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map((cell, index) => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps({
                                                                        className: (
                                                                            cell.column as any
                                                                        ).className,
                                                                    })}
                                                                >
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <>
                                        {emptyStateMessage && <div className="font-light">{t(emptyStateMessage)}</div>}
                                    </>
                            }
                        </>
                    }
                </div>
            ) : (
                <table
                    {...getTableProps()}
                    className="w-full font-light border overflow-x-auto block lg:table bg-white"
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps({
                                            className: (column as any)
                                                .className,
                                        })}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td
                                                {...cell.getCellProps({
                                                    className: (
                                                        cell.column as any
                                                    ).className,
                                                })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
};

Table.variants = Variants;
export default Table;
