import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type ChangeWarehouse = {
    endpoint: string;
    body: {
        storagearea_id: string;
        quantity: string;
    };
}

export default function useAlternativeWarehouseMutations() {
    const queryClient = useQueryClient();

    // CHANGE WAREHOUSE
    const changeWarehouse = useMutation(async ({ endpoint, body }: ChangeWarehouse) => {
        return await client.put(endpoint, body);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["cart"]);
            queryClient.invalidateQueries(["offer"]);
        }
    });

    return { changeWarehouse };
}
