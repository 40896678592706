import { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { Button } from "../components/Button";
import { Message } from "../components/Message";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import client from "../api/client";
import { useTranslation } from "react-i18next";
import { Loader } from "../components/Loader";

const login_url = "/login";

const Login = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const { setUser } = useAuthContext();
    const { t } = useTranslation();

    // get error from query
    const [searchParams] = useSearchParams();
    const microsoftError = searchParams.get("e");

    // ref hooks
    const emailRef = useRef<HTMLInputElement>(null!);

    // state
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);


    // set the focus on the email input
    useEffect(() => {
        emailRef.current.focus();
        if (microsoftError === "401") {
            setError("Email non registrata");
        }
    }, [microsoftError]);

    // handle submit form
    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const params = JSON.stringify({
            email: email,
            password: password
        });

        try {
            const res = await client.post(login_url, params);
            // console.log(res);

            // set auth token/permissions and clear input values
            setEmail("");
            setPassword("");

            // set context and session storage
            setUser({ ...res.data.user, auth_token: res.data.auth_token, customers: res?.data?.customers, constants: res?.data?.constants, storage_areas: res?.data?.storage_areas });

            // set user language
            await i18n.changeLanguage(res.data.user.interface_language_id);

            const intendedRoute: string | null = sessionStorage.getItem("intended_route");
            sessionStorage.removeItem("intended_route");

            intendedRoute ? navigate(intendedRoute) : navigate("/dashboard");
        } catch (err: any) {
            setIsLoading(false);
            setError(err.response.data.message);
            setPassword("");
        }
    };

    return (
        <div className="flex flex-col items-end justify-center h-full bg-magazzino bg-cover">
            {isLoading && <Loader/>}

            <div className="flex flex-col xl:flex-row gap-x-10 items-stretch w-full lg:max-w-[40%] bg-black/60 h-full">
                <form onSubmit={handleSubmit} className={"mt-auto mb-auto mx-auto"}>
                    <fieldset className="w-100 space-y-6 m-10 max-w-screen-sm">

                        <legend className="block w-full text-white">
                            <span className="block text-5xl font-bold">{t("Dear Customer Welcome to E-Service")}</span>
                        </legend>

                        {error && <Message variant={Message.variant.error} open={!!error}>{error}</Message>}

                        <div>
                            <label className="text-white" htmlFor="email">{t("Indirizzo e-mail")}</label>
                            <input
                                type="email"
                                id="email"
                                ref={emailRef}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <div>
                            <label className="text-white" htmlFor="password">{t("Password")}</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        <div className="text-white">
                            <small>{t("Non ricordi più la password?")} <Link to="/reset-password" className="font-bold underline hover:no-underline">{t("Reimpostala")}</Link></small>
                        </div>

                        <div>
                            <Button size={Button.size.full} variant={Button.variant.tertiary}>{t("LOG IN CUSTOMER")}</Button>
                        </div>

                    </fieldset>
                </form>

                {/* <div className="flex flex-col items-center justify-center p-20 xl:p-36 bg-gray-200">
                    <Title variant={Title.variant.secondary}>Sei già un cliente Rothoblaas?</Title>
                    <p><Button variant={Button.variant.primary} href="https://www.google.it">Richiedi il tuo account</Button></p>
                </div> */
                }
            </div>
        </div>
    );
};

export default Login;
