import { useTranslation } from "react-i18next";
import useProfile from "../api/profile/useProfile";
import { Title } from "../components/Title";
import { getCurrencyDecimals } from "../utils/getCurrencyDecimals";
import Table from "../components/Table";
import { convertMiliseconds, getExpiredDays } from "../utils/utils";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

type Props = {
    selectedCustomer: any;
};

export default function Financial({ selectedCustomer }: Props) {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    const { data: profile } = useProfile(selectedCustomer?.code);

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);
    const numberFormat = profile?.data ? new Intl.NumberFormat(profile?.data?.language_id, { style: "currency", currency: profile?.data?.currency, minimumFractionDigits: getCurrencyDecimals(profile?.data?.currency) }) : null;
    const residualCredit = profile?.data?.finance?.creditlimit - profile?.data?.finance?.deliverednotinvoiced - profile?.data?.finance?.undelivered - profile?.data?.finance?.unpaid - profile?.data?.finance?.invoicednottransferred;
    const totalBlockedAmount = profile?.data?.unprocessedOrders?.reduce((acc: number, obj: any) => acc + parseFloat(obj.totalnetvalue), 0);
    const totalInvoicesAmount = profile?.data?.customerInvoices?.reduce((acc: number, obj: any) => {
        const netAmount = parseFloat(obj.netamount);
        return !isNaN(netAmount) ? acc + netAmount : acc;
    }, 0);

    const opened_orders =
        (Number(profile?.data?.finance?.undelivered) || 0) +
        (Number(profile?.data?.finance?.deliverednotinvoiced) || 0) +
        (Number(profile?.data?.finance?.invoicednottransferred) || 0);


    const invoicesColumns = [
        {
            Header: t("Numero fattura").toString(),
            accessor: "number",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div>
                        <div className="font-normal">
                            {props?.row.original.number}
                        </div>
                        <div>{t(props?.row.original.type)}</div>
                    </div>
                );
            },
        },
        {
            Header: t("Data scadenza").toString(),
            accessor: "due_date",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return dateFormat.format(
                    new Date(props?.row.original.due_date)
                );
            },
        },
        {
            Header: t("Ritardo").toString(),
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return props.row.original.type === "Invoice"
                    ? getExpiredDays(props.row.original.due_date) > 0
                        ? convertMiliseconds(
                            getExpiredDays(props.row.original.due_date),
                            "d"
                        )
                        : ""
                    : "";
            },
        },
        {
            Header: t("Totale").toString(),
            accessor: "amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
        {
            Header: t("Pagato").toString(),
            accessor: "paid_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.paid_amount);
            },
        },
        {
            Header: t("Aperto").toString(),
            accessor: "open_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.open_amount);
            },
        },
    ];

    return <div className="px-6">
        <Title className="mb-6" variant={Title.variant.secondary}>{t("Situazione finanziaria cliente")}</Title>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="col-span-1 border p-10 flex flex-col gap-6 bg-table-secondary">
                <span className="text-xl">{t("Ruoli")}</span>
                <span>{t("TS")}: <span className="font-light">{profile?.data?.ts_fullname}</span></span>
                <span>{t("CS-1")}: <span className="font-light">{profile?.data?.customerService1?.name}</span></span>
                <span>{t("CS-2")}: <span className="font-light">{profile?.data?.customerService2?.name}</span></span>
                <span>{t("CM")}: <span className="font-light">{profile?.data?.creditManagment?.name}</span></span>
                <span>{t("ASM")}: <span className="font-light">{profile?.data?.areaManager?.name}</span></span>
                <span>{t("RSM")}: <span className="font-light">{profile?.data?.regionManager?.name}</span></span>
                <span>{t("DSM")}: <span className="font-light">{profile?.data?.divisionManager?.name}</span></span>
            </div>
            <div className="md:col-span-2 border p-10 flex flex-col gap-6">
                <div className="grid grid-cols-2 gap-8">
                    <div className="flex flex-col">
                        <span>{t("Insoluto")}</span>
                        <span className="font-light">{numberFormat?.format(profile?.data?.finance?.unpaidexpired)}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Partite aperte")}</span>
                        <span className="font-light">{numberFormat?.format(profile?.data?.finance?.unpaid)}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Fido totale")}</span>
                        <span className="font-light">{numberFormat?.format(profile?.data?.finance?.creditlimit)}</span>
                    </div >
                    <div className="flex flex-col">
                        <span>{t("Fido residuo")}</span>
                        <span className={`font-light ${residualCredit < 0 ? "text-rose-500" : ""}`}>{numberFormat?.format(residualCredit)}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Totale ordini bloccati E-service")}</span>
                        <span className={`font-light ${totalBlockedAmount < 0 ? "text-rose-500" : ""}`}>{numberFormat?.format(totalBlockedAmount)}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Ordini aperti")}</span>
                        <span className="font-light">{numberFormat?.format(opened_orders)}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Metodo di pagamento")}</span>
                        <span className="font-light">{profile?.data?.finance?.paymentterm?.description} {profile?.data?.finance?.paymentmethod?.description}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Totale fatture ivate ultimi 24 mesi")}</span>
                        <span className="font-light">{numberFormat?.format(totalInvoicesAmount)}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Rothoblaas rating")}</span>
                        <span className={profile?.data?.invoicing?.internalrating === 3 ? "text-green-500" : profile?.data?.invoicing?.internalrating === 2 ? "text-yellow-500" : "text-rose-500"}>
                            {profile?.data?.invoicing?.internalrating === 0 && "No rating"}
                            {profile?.data?.invoicing?.internalrating === 1 && "Red"}
                            {profile?.data?.invoicing?.internalrating === 2 && "Yellow"}
                            {profile?.data?.invoicing?.internalrating === 3 && "Green"}
                        </span>
                    </div>
                    <div className="flex flex-col">
                        <span>{t("Finance rating")}</span>
                        <span className={profile?.data?.invoicing?.failurescore == 3 ? "text-green-500" : profile?.data?.invoicing?.failurescore === 2 ? "text-yellow-500" : "text-rose-500"}>
                            {profile?.data?.invoicing?.failurescore === 0 && "No rating"}
                            {profile?.data?.invoicing?.failurescore === 1 && "Red"}
                            {profile?.data?.invoicing?.failurescore === 2 && "Yellow"}
                            {profile?.data?.invoicing?.failurescore === 3 && "Green"}
                        </span>
                    </div>
                </div>
            </div>
        </div >
        <div className="col-span-2 mt-8">
            {
                profile &&
                <Table
                    variants={Table.variants.expandable}
                    label="Elenco partite aperte"
                    data={profile?.data?.open_nav_payments}
                    columns={invoicesColumns}
                    emptyStateMessage="Nessuna fattura aperta"
                />
            }
        </div>
    </div >;
}
