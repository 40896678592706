import { useQuery } from "@tanstack/react-query";
import client from "../client";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

export default function useExtraServices(id: string | undefined, isOffer = false) {
    const { can } = useAuthContext();

    let endpoint = "";
    if (isOffer) {
        endpoint = `offers/${id}/extra-services`;
    } else {
        endpoint = `carts/${id}/extra-services`;
    }

    // GET PRICE LIST
    const fetchExtraServices = async () => {
        const res = await client.get(endpoint);
        return res.data;
    };

    const { data: extraServices, isLoading, isFetching, error } = useQuery(["extra_services", id],
        () => fetchExtraServices(),
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            retry: 0,
            enabled: can(["can_see_prices"])
        }
    );

    return { extraServices, isLoading, isFetching, error };
}
