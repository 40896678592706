import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";

type CreateOrderParams = {
    cart_id: number,
    client_reference?: string,
    rotho_notes?: string,
    order_classification?: string | null,
    delivery_restriction?: string,
};

type OrderParams = {
    order_id: number
};

type UnblockOrderParams = {
    order_id: number,
    reason?: string,
    action?: "unblock" | "cancel",
    blocks?: {
        id: number,
        reason: string,
        action: "unblock" | "cancel"
    }[];
};

type DuplicateOrderParams = {
    order_id: number,
    customer_code: string
};

export const useOrderMutations = () => {
    const queryClient = useQueryClient();

    const { resetShippingContext } = useShippingContext();

    // CREATE ORDER
    const createOrder = useMutation(async (params: CreateOrderParams) => {
        return await client.post("orders", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["orders"]);
            queryClient.invalidateQueries(["offers"]);
            queryClient.invalidateQueries(["carts"]);
        }
    });

    // DUPLICATE ORDER
    const duplicateOrder = useMutation(async (params: DuplicateOrderParams) => {
        return await client.post("duplicated-orders", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["orders"]);
            resetShippingContext();
        }
    });

    // UNBLOCK O OR CANCEL ORDER
    const unblockOrCancelOrder = useMutation(async (params: UnblockOrderParams) => {
        return await client.post("handled-blocked-orders", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["orders"]);
            queryClient.invalidateQueries(["order"]);
            queryClient.invalidateQueries(["blocked-order"]);
        }
    });

    // RESEND ORDER TO SEMIRAMIS
    const resendOrderToSemiramis = useMutation(async (params: OrderParams) => {
        return await client.post(`orders/${params.order_id}/resend-to-semiramis`);
    }, {
        onSuccess: () => { queryClient.invalidateQueries(["orders"]); }
    });

    // DELETE ORDER WITH NO SEMIRAMIS NUMBER
    const deleteOrder = useMutation(async (params: OrderParams) => {
        return await client.delete(`orders/${params.order_id}`);
    }, {
        onSuccess: () => { queryClient.invalidateQueries(["orders"]); }
    });

    return { createOrder, duplicateOrder, unblockOrCancelOrder, resendOrderToSemiramis, deleteOrder };
};
