import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type AddProductParams = {
    cart_id: string,
    body: {
        product_code: string | undefined,
        quantity: number
        discount_1?: string
        discount_2?: string
    }
};

type DeleteProductParams = {
    cart_id: string,
    product_id: string
};

export type UpdateProductSpecialParams = {
    cart_id: string,
    product_id: string,
    description?: string,
    uom_id?: string,
};

type UpdateProductParams = {
    cart_id: string,
    product_id: string,
    body: {
        quantity: number,
        discount_1?: string,
        discount_2?: string,
        manualNetPrice?: string
    }
};

export type ReplaceProductParams = {
    cart_id: string,
    product_id: string,
    body: {
        quantity: number,
        product_code: string
    }
};

type CartIdParam = {
    cart_id: number
};

export function useCartItemsMutations() {
    const queryClient = useQueryClient();

    // ADD PRODUCT
    const addProduct = useMutation(async (productInfo: AddProductParams) => {
        return await client.post(`carts/${productInfo.cart_id}/items`, productInfo.body);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["cart"]);
            queryClient.invalidateQueries(["suggested-products"]);
        }
    });

    // DELETE PRODUCT
    const deleteProduct = useMutation(async (params: DeleteProductParams) => {
        return await client.delete(`/carts/${params.cart_id}/items/${params.product_id}`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["cart"]); } });

    // UPDATE PRODUCT
    const updateProduct = useMutation(async (params: UpdateProductParams) => {
        return await client.put(`/carts/${params.cart_id}/items/${params.product_id}`, params.body);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["cart"]);
            queryClient.invalidateQueries(["alternativeWarehouse"]);
        }
    });

    // DELETE ALL PRODUCTS
    const deleteAllProducts = useMutation(async (params: CartIdParam) => {
        return await client.delete(`/carts/${params.cart_id}/items`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["cart"]); } });

    // UPDATE DESCRIPTION
    const updateProductSpecial = useMutation(async (params: UpdateProductSpecialParams) => {
        return await client.put(`/carts/${params.cart_id}/items/${params.product_id}/special`, params);
    }, { onSuccess: () => { queryClient.invalidateQueries(["cart"]); } });

    // REPLACE PRODUCT
    const replaceProduct = useMutation(async (params: ReplaceProductParams) => {
        return await client.post(`/carts/${params.cart_id}/alternative-products/${params.product_id}`, params.body);
    }, { onSuccess: () => { queryClient.invalidateQueries(["cart"]); } });

    return { addProduct, deleteProduct, updateProduct, deleteAllProducts, updateProductSpecial, replaceProduct };
}
