import { useTranslation } from "react-i18next";
import { RiErrorWarningLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Button } from "../Button";
import { Title } from "../Title";
import useBlockedOrder from "../../api/orders/useBlockedOrder";
import Skeleton from 'react-loading-skeleton';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import 'react-loading-skeleton/dist/skeleton.css'
import { useState } from "react";
import { convertMiliseconds, getExpiredDays } from "../../utils/utils";
import Table from "../Table";
import { CgProfile } from "react-icons/cg";


export const OrderBlocked = ({ order }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, roles } = useAuthContext();

    // state
    const [openBlockDetails, setOpenBlockDetails] = useState<any>(false);

    const goToOrders = () => {
        navigate("/orders");
    };

    const goToOrder = () => {
        navigate(`/orders/${order?.data?.order_id}`);
    };

    const numberFormat = order?.data?.customer ? new Intl.NumberFormat(order?.data?.customer?.language_id, { style: "currency", currency: order?.data?.customer?.finance.currency, minimumFractionDigits: getCurrencyDecimals(order?.data?.customer?.finance.currency), maximumFractionDigits: getCurrencyDecimals(order?.data?.customer?.finance.currency) }) : null;
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const totalBlockedAmount = order?.data?.customer?.unprocessedOrders?.reduce((acc: number, obj: any) => acc + parseFloat(obj.totalnetvalue), 0);
    const residualCredit = order?.data?.customer?.finance?.creditlimit - order?.data?.customer?.finance?.unpaid - order?.data?.customer?.finance?.undelivered - order?.data?.customer?.finance?.deliverednotinvoiced - order?.data?.customer?.finance?.invoicednottransferred - totalBlockedAmount;

    const invoicesColumns = [
        {
            Header: t("Numero fattura").toString(),
            accessor: "number",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div>
                        <div className="font-normal">
                            {props?.row.original.number}
                        </div>
                        <div>{t(props?.row.original.type)}</div>
                    </div>
                );
            },
        },
        {
            Header: t("Data scadenza").toString(),
            accessor: "due_date",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return dateFormat.format(
                    new Date(props?.row.original.due_date)
                );
            },
        },
        {
            Header: t("Ritardo").toString(),
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return props.row.original.type === "Invoice" ? getExpiredDays(props.row.original.due_date) > 0 ? convertMiliseconds(getExpiredDays(props.row.original.due_date), "d") : "" : "";
            },
        },
        {
            Header: t("Totale").toString(),
            accessor: "amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
        {
            Header: t("Pagato").toString(),
            accessor: "paid_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.paid_amount);
            },
        },
        {
            Header: t("Aperto").toString(),
            accessor: "open_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.open_amount);
            },
        },
    ];

    const toggleBlockDetails = (blockId: string) => {
        setOpenBlockDetails((prevState: any) => ({
            ...prevState,
            [blockId]: !prevState[blockId]
        }));
    };

    return (
        <div className='flex flex-col items-center h-full'>
            <div className='mb-8'>
                <RiErrorWarningLine className='text-rose-500 text-[50px] block m-auto mb-4' />
                <Title variant={Title.variant.secondary}>{t("L'ordine è stato bloccato.")}</Title>
            </div>

            {
                roles(user?.constants?.external_roles) ?
                    <div className="w-4/5 text-left font-light space-y-4">

                        <div className='border p-6 space-y-4'>
                            <p className="font-light text-gray-600">{t("Verrai notificato non appena lo stato dell'ordine sarà modificato. Per ulteriori informazioni puoi rivolgerti al tuo Tecnico Commerciale.")}</p>
                        </div>
                    </div>

                    :

                    <div className="w-full">
                        <div className="flex justify-center">
                            <div className="w-4/5 text-left font-light space-y-4">
                                <p className="font-light text-gray-600">{t("Una notifica mail è già stata inviata ai responsabili per lo sblocco. Verrai notificato non appena lo stato dell'ordine sarà modificato.")}</p>

                                <div className='border p-6 space-y-4'>
                                    <Title variant={Title.variant.secondary}>{t("Dati cliente")}</Title>
                                    <p>{order?.data?.customer?.name} - {order?.data?.customer?.code}</p>
                                </div>

                                {
                                    order?.data?.blocks?.length > 0 &&
                                    <div className="border p-6 space-y-4">
                                        <span className="font-semibold">{t("Blocchi presenti sull'ordine")}:</span>
                                        {
                                            order?.data?.blocks?.map((block: any) => {
                                                return <div className="flex flex-col bg-white border p-4 rounded" key={block.id}>
                                                    <div className="flex flex-wrap justify-between items-start">
                                                        <div className="grid grid-cols-[auto,1fr] items-center gap-2">
                                                            {
                                                                openBlockDetails[block.id] ?
                                                                    <FaMinus
                                                                        className="w-4 h-4 cursor-pointer"
                                                                        onClick={() => toggleBlockDetails(block.id)}

                                                                    />
                                                                    :
                                                                    <FaPlus
                                                                        className="w-4 h-4 cursor-pointer"
                                                                        onClick={() => toggleBlockDetails(block.id)}
                                                                    />
                                                            }
                                                            <div className="flex gap-2">
                                                                <span className="font-semibold">
                                                                    {t(block?.reason === "max-discount" ? block?.reason : block?.reason === "creditlimit" ? "Fuori fido" : "Insoluto")}
                                                                </span>
                                                                {
                                                                    block?.unblockable_by &&
                                                                    <div className="flex gap-1 items-center border px-2 rounded shadow-[0px_1px_2px_0px_rgba(206,206,206,0.25)]">
                                                                        <CgProfile />
                                                                        {
                                                                            (!block?.unblocked_at && !block?.canceled_at) ?
                                                                                <span>{t("Autorizzabile da")}: {block?.unblockable_by}</span>
                                                                                :
                                                                                block?.unblocked_at ?
                                                                                    <span>Autorizzato da: {block?.managedBy?.name}</span>
                                                                                    :
                                                                                    <span>Non autorizzato da: {block?.managedBy?.name}</span>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            {block?.unblocked_at && <><span></span> {/* Colonna vuota */}<span className="text-sm text-zinc-500">{t(block?.unblock_reason)}</span></>}
                                                            {block?.canceled_at && <> <span></span> {/* Colonna vuota */}<span className="text-sm text-zinc-500">{t(block?.cancel_reason)}</span></>}
                                                        </div>
                                                        {
                                                            (block?.unblocked_at || block?.canceled_at) &&
                                                            <div className="flex items-center gap-x-2 border rounded-md w-fit px-3 py-0.5 shadow-[0px_1px_2px_0px_rgba(206,206,206,0.25)] text-sm">
                                                                {block?.unblocked_at && <div className="flex gap-2 items-center"><div className="rounded-full w-2 h-2 bg-green-500" />{t("Autorizzato")}</div>}
                                                                {block?.canceled_at && <div className="flex gap-2 items-center"><div className="rounded-full w-2 h-2 bg-red-500" />{t("Non autorizzato")}</div>}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className={`${openBlockDetails[block.id] ? "max-h-[1000px]" : "max-h-0"} transition-all duration-400 overflow-hidden`}>
                                                        {
                                                            block?.reason === "max-discount" ? <>
                                                                <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1 bg-neutral-weaker`}>
                                                                    <div className="w-full overflow-x-auto">
                                                                        <table className="w-full border-collapse border overflow-x-auto">
                                                                            <thead>
                                                                                <tr className="text-left border-b">
                                                                                    <th className="px-4 py-2 whitespace-nowrap">{t("Codice articolo")}</th>
                                                                                    <th className="px-4 py-2 whitespace-nowrap">{t("Quantità")}</th>
                                                                                    <th className="px-4 py-2 whitespace-nowrap">{t("Sconto %")}</th>
                                                                                    <th className="px-4 py-2 whitespace-nowrap">{t("Sconto 2 %")}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {order?.data?.blocks
                                                                                    ?.filter((block: any) => block?.reason === "max-discount")
                                                                                    ?.flatMap((block: any) =>
                                                                                        block?.blockedItems?.map((item: any) => (
                                                                                            <tr key={item?.id} className="px-4 py-2">
                                                                                                <td className="px-4 py-2">{item?.product?.code}</td>
                                                                                                <td className="px-4 py-2">{item?.totalqty}</td>
                                                                                                <td className="px-4 py-2">{item?.discount1}</td>
                                                                                                <td className="px-4 py-2">{item?.discount2 ?? "-"}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </> : <div className="grid grid-cols-2">
                                                                {/* Display finance data and payment summary */}
                                                                <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                                    <p>
                                                                        {t("Insoluto")}:{" "}
                                                                        <span className={`${order?.data?.customer?.finance?.unpaidexpired > 0 ? "text-rose-500" : ""}`}>
                                                                            {numberFormat ? numberFormat?.format(order?.data?.customer?.finance?.unpaidexpired) : ""}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Partite aperte")}:{" "}
                                                                        {numberFormat ? numberFormat?.format(order?.data?.customer?.finance?.unpaid) : ""}
                                                                    </p>
                                                                    <p>
                                                                        {t("Fido")}:{" "}
                                                                        {numberFormat ? numberFormat?.format(order?.data?.customer?.finance?.creditlimit) : ""}
                                                                    </p>
                                                                    <p>
                                                                        {t("Ordine attuale")}:{" "}
                                                                        {numberFormat ? numberFormat?.format(order?.data?.totalnetvalue) : ""}
                                                                    </p>
                                                                    <p>
                                                                        {t("Totale ordini bloccati E-service")}:{" "}
                                                                        {numberFormat?.format(totalBlockedAmount)}
                                                                    </p>
                                                                </div>
                                                                <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                                    {/* Display more finance and rating data here */}
                                                                    <p>
                                                                        {t("Fido residuo")}:{" "}
                                                                        <span className={`${residualCredit < 0 ? "text-rose-500" : ""}`}>
                                                                            {numberFormat ? numberFormat?.format(residualCredit) : ""}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Totale fatture ivate ultimi 24 mesi")}:{" "}
                                                                        <span>
                                                                            {numberFormat ? numberFormat.format(order?.data?.customer?.customerInvoices?.reduce((acc: number, obj: any) => acc + obj.netamount, 0)) : ""}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Rothoblaas Rating")}:{" "}
                                                                        <span className={order?.data?.customer?.invoicing?.internalrating === 3 ? "text-green-500" : order?.data?.customer?.invoicing?.internalrating === 2 ? "text-yellow-500" : "text-rose-500"}>
                                                                            {order?.data?.customer?.invoicing?.internalrating === 0 && "No rating"}
                                                                            {order?.data?.customer?.invoicing?.internalrating === 1 && "Red"}
                                                                            {order?.data?.customer?.invoicing?.internalrating === 2 && "Yellow"}
                                                                            {order?.data?.customer?.invoicing?.internalrating === 3 && "Green"}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Finance Rating")}:{" "}
                                                                        <span
                                                                            className={order?.data?.customer?.invoicing?.failurescore === 3 ? "text-green-500" : order?.data?.customer?.invoicing?.failurescore === 2 ? "text-yellow-500" : "text-rose-500"}>
                                                                            {order?.data?.customer?.invoicing?.failurescore === 0 && "No rating"}
                                                                            {order?.data?.customer?.invoicing?.failurescore === 1 && "Red"}
                                                                            {order?.data?.customer?.invoicing?.failurescore === 2 && "Yellow"}
                                                                            {order?.data?.customer?.invoicing?.failurescore === 3 && "Green"}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 col-span-2 mt-4`}>
                                                                    {order && (
                                                                        <Table
                                                                            variants={Table.variants.expandable}
                                                                            label="Estratto conto"
                                                                            data={order?.data?.customer?.open_nav_payments}
                                                                            columns={invoicesColumns}
                                                                            emptyStateMessage="Nessuna fattura aperta"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className="flex flex-col gap-y-2 mt-8">
                <Button
                    variant={Button.variant.secondary}
                    onClick={goToOrder}
                >
                    {t("Mostra dettagli ordine")}
                </Button>
                <Button
                    variant={Button.variant.text}
                    onClick={goToOrders}
                >
                    {t("Torna alla lista ordini")}
                </Button>
            </div>
        </div>
    );
};
