import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../../hooks/use-context/useAuthContext";
import {getCurrencyDecimals} from "../../../utils/getCurrencyDecimals";
import {Button} from "../../Button";
import client from "../../../api/client";
import {Loader} from "../../Loader";
import {CartClientInfo} from "../CartClientInfo";
import useCart from "../../../api/carts/useCart";
import ExtraServicesSummary from "./ExtraServicesSummary";
import CartCostsSummary from "./CartCostsSummary";
import {useQuery} from "@tanstack/react-query";
import ShippingInfoSummary from "./ShippingInfoSummary";
import useOffersMutations from "../../../api/offers/useOffersMutations";
import {useOrderMutations} from "../../../api/orders/useOrderMutations";
import {useShippingContext} from "../../../hooks/use-context/useShippingContext";
import ProductsSummary from "../../commom/ProductsSummary";
import {Slide, ToastContainer, toast} from "react-toastify";
import OrderConfirmation from "../../templates/OrderConfirmation";
import RothoNotesSummary from "./RothoNotesSummary";

type CartSummaryProps = {
    setErrorMsg: React.Dispatch<React.SetStateAction<string | null>>
};

export const CartSummary = ({setErrorMsg}: CartSummaryProps) => {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    // state
    const [OpenConfirmOrder, setOpenConfirmOrder] = useState(false);

    // context
    const {roles, can, user} = useAuthContext();
    const {client_reference, rotho_notes, resetShippingContext, delivery_restriction} = useShippingContext();

    // react query
    const {data: cart} = useCart(id);
    const {data: summary} = useQuery(["summary"], () => getSummaryInfo(), {refetchOnWindowFocus: false, enabled: !!cart && can(["can_see_prices"])});
    const {createOrder} = useOrderMutations();
    const {proposeOffer} = useOffersMutations();

    const numberFormat = cart ? new Intl.NumberFormat(cart?.data?.customer?.language_id, {style: "currency", currency: cart?.data?.customer?.currency, minimumFractionDigits: getCurrencyDecimals(cart?.data?.customer?.currency), maximumFractionDigits: getCurrencyDecimals(cart?.data?.customer?.currency)}) : null;

    const handlePreviousStepClick = () => {
        navigate(-1);
    };

    const handleConfirmClick = async (orderClassification: string | null) => {
        try {
            const res = await createOrder.mutateAsync({cart_id: cart?.data?.id, client_reference, rotho_notes, order_classification: orderClassification, delivery_restriction: delivery_restriction.toString()});

            resetShippingContext();
            navigate(`/order-sent/${res?.data?.data?.order_id}`);
        } catch (err: any) {
            if (err?.response?.status === 409 || err?.response?.status === 403) {
                setErrorMsg(t(err.response.data.message));
                navigate(`/carts/${cart?.data?.id}`);
            } else if (err?.response?.status === 422) {
                setErrorMsg(t("L’iva è stata aggiornata. Verifica i nuovi prezzi e procedi con le operazioni."));
                navigate(`/carts/${cart?.data?.id}`);
            } else {
                toast.error(err.response.data.message);
            }
        }
    };

    // CREATE OFFER
    const handleCreateOffer = async () => {
        await proposeOffer.mutateAsync({cart_id: cart?.data?.id});
        navigate(`/offers/${cart?.data?.id}/created`);
    };

    // GET SUMMARY INFO
    const getSummaryInfo = useCallback(async () => {
        const res = await client.get(`/carts/${cart?.data?.id}/summary`);
        return res.data;
    }, [cart]);

    return (
        <>
            {(createOrder.isLoading || proposeOffer.isLoading) && <Loader/>}

            {/* react toastfy */}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            {
                cart &&
                <>
                    {/* order confirmation modal */}
                    <OrderConfirmation data={cart?.data} open={OpenConfirmOrder} setOpen={setOpenConfirmOrder} handleConfirmClick={handleConfirmClick} clientReference={cart?.data?.client_reference}/>

                    <CartClientInfo data={cart}/>

                {/* spedizione consegna e pagamento */}
                    <ShippingInfoSummary data={cart} numberFormat={numberFormat} summary={summary?.data}/>

                    <ProductsSummary data={cart} summary={summary?.data} availabitiliy={true}/>

                    <ExtraServicesSummary data={cart}/>

                    <RothoNotesSummary data={cart}/>

                {
                    can(["can_see_prices"]) &&
                    <CartCostsSummary summary={summary?.data} data={cart}/>
                }

                    <div className="text-xs py-2 px-6 space-x-1">
                        &#x2022; <a target="_blank" className="text-secondary hover:underline" href="https://www.rothoblaas.it/condizioni-generali-di-vendita">{t("Condizioni di vendita")}</a>
                    </div>

                {/* WARNING MESSAGES */}
                    <div className="py-2 px-6">
                        {/* Warning messages iva & shipping methods */}
                        {
                            (!cart?.data?.shipping_info?.shipping_methods_price || summary?.data?.total_iva === 0) &&
                            <>
                                {/* case no freight cost no iva */}
                                {!cart?.data?.shipping_info?.shipping_methods_price && !summary?.data?.tax_calculation && !cart?.data?.shipping_info?.manual_freight_cost ? <p className="text-xs text-red-500"> {t("Calcolo costo di trasporto e IVA non disponibile, gli importi saranno aggiornati in conferma d’ordine.")}</p> : null}
                                {/* case no iva */}
                                {!cart?.data?.shipping_info?.shipping_methods_price && !summary?.data?.tax_calculation ? <p className="text-xs text-red-500"> {t("Calcolo IVA non disponibile, l'importo sarà aggiornato in conferma d’ordine.")}</p> : null}
                                {/* case no freight cost (check if this case actually exists beacause when there's no freight cost probably cannot calculate iva) */}
                                {!cart?.data?.shipping_info?.shipping_methods_price && summary?.data?.tax_calculation && !cart?.data?.shipping_info?.manual_freight_cost ? <p className="text-xs text-red-500"> {t("Calcolo costo di trasporto non disponibile, l'importo sarà aggiornato in conferma d’ordine.")}</p> : null}
                            </>
                        }

                        {/* warning messages legallock o blacklist per external roles */}
                        {
                            ((cart?.data?.customer?.legallock || cart?.data?.customer?.invoicing?.legallock || cart?.data?.customer?.finance?.blacklist) &&
                                roles(user?.constants?.external_roles)) &&
                            <div>
                                <p className="text-xs text-red-500">{t("Non è possibile procedere con offerte o ordini. Per maggiori informazioni rivolgiti al tuo tecnico commerciale")}</p>
                            </div>
                        }

                        {/* warning messages legallock */}
                        {
                            ((cart?.data?.customer?.legallock || cart?.data?.customer?.invoicing?.legallock)
                                && !roles(user?.constants?.external_roles)) &&
                            <div>
                                <p className="text-xs text-red-500">{t("Cliente con blocco finanziario. Possibile procedere solo con carrelli e offerte. Per maggiori informazioni rivolgiti al Credit Management")}</p>
                            </div>
                        }

                        {/* warning messages blacklist */}
                        {
                            (cart?.data?.customer?.finance?.blacklist
                                && !roles(user?.constants?.external_roles)) &&
                            <div>
                                <p className="text-xs text-red-500">{t("Cliente bloccato per controllo blacklist. Non è possibile procedere con offerte o ordini. Per maggiori informazioni rivolgiti al reparto Logistica")}</p>
                            </div>
                        }

                        {/* warning message for items with priceclassification S-H0-45 */}
                        {
                            cart?.data?.items.find((item: any) => item?.priceclassification?.startsWith("S-H0-45")) &&
                            <div>
                                <p className="text-xs text-red-500">{t("Rivolgiti al tuo TC per trasformare in ordine.")}</p>
                            </div>
                        }
                    </div>

                    {/* buttons */}
                    <div className="flex justify-between gap-x-4 mt-8">
                        <div className="flex items-center">
                            <Button variant={Button.variant.text} icon={Button.icon.letfArrow} onClick={handlePreviousStepClick}>
                                {t("Indietro")}
                            </Button>
                        </div>
                        <div className="space-x-2">
                            {
                                can(["can_make_offer"]) &&
                                <Button variant={Button.variant.secondary} onClick={handleCreateOffer} isDisabled={!cart?.data?.shipping_info?.shipping_methods_price && !cart?.data?.shipping_info?.manual_freight_cost}>
                                    {t("Crea offerta")}
                                </Button>
                            }

                            {
                                // button is disabled in case legallock === 1 or cart contains item with priceclassification S-H0-45
                                can(["can_make_order"]) &&
                                cart?.data?.customer?.type !== "DUMMY CUSTOMER" &&
                                <Button
                                    onClick={() => setOpenConfirmOrder(true)}
                                    isDisabled={cart?.data?.customer?.legallock
                                        || cart?.data?.customer?.invoicing?.legallock
                                        || cart?.data?.customer?.finance?.blacklist
                                        || cart?.data?.items.find((item: any) => item?.priceclassification?.startsWith("S-H0-45"))
                                        || cart?.data?.items.find((item: any) => item?.priceclassification?.startsWith("S-H0-44"))
                                        || cart?.data?.items.find((item: any) => item?.priceclassification?.startsWith("S-H0-41"))
                                        || cart?.data?.items.find((item: any) => item?.code === 'SPECIAL')
                                    }
                                >
                                    {t("Conferma ordine")}
                                </Button>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
};
