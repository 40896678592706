import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {HiChevronDown, HiChevronUp} from "react-icons/hi";
import {useTable, useSortBy, usePagination, Column, useExpanded} from "react-table";
import {useAuthContext} from "../../hooks/use-context/useAuthContext";
import {getCurrencyDecimals} from "../../utils/getCurrencyDecimals";

type searchParams = {
    "filter[code]"?: string,
    "filter[name]"?: string,
};

type OrdersTableProps = {
    items: any,
    setSearchParams: React.Dispatch<React.SetStateAction<searchParams>>
    renderRowSubComponent: any
};

export const OutstandingOrdersTable = ({renderRowSubComponent, items, setSearchParams}: OrdersTableProps) => {
    const {t} = useTranslation();

    // const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                id: "expander",
                Header: "",
                disableSortBy: true,
                className: "border-b p-4",
                Cell: ({row}: any) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row

                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                            tabIndex: 0
                        })}
                    >
                        {row.isExpanded ? <HiChevronUp className="text-xl"/> : <HiChevronDown className="text-xl"/>}
                    </span>
            },
            {
                Header: t("Codice cliente").toString(),
                accessor: "code",
                disableSortBy: true,
                className: "border-b p-4 text-left"
            },
            {
                Header: t("Nome cliente").toString(),
                accessor: "name",
                disableSortBy: true,
                className: "border-b p-4 text-left"
            },
            {
                Header: t("Indirizzo").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props) => {
                    return <span>{`${props.row.original.street}, ${props.row.original.city} ${props.row.original.postalcode}`}</span>;
                }
            },
            {
                Header: t("Totale").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-right",
                Cell: (props) => {
                    const currency = props.row.original.currency;
                    const language_id = props.row.original.language_id;
                    const numberFormat = (currency && language_id) ? new Intl.NumberFormat(language_id, {
                        style: "currency",
                        currency: currency,
                        minimumFractionDigits: getCurrencyDecimals(currency),
                        maximumFractionDigits: getCurrencyDecimals(currency)
                    }) : null;

                    return <span>{numberFormat && numberFormat.format(props.row.original.outstandingItems?.reduce(function (acc: any, item: any) {
                        return acc + (item.qty - item.delivered_qty) * item.net_price;
                    }, 0))}</span>;
                }
            },
        ],
        [t]
    );

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: {sortBy}
    } = useTable({
        columns,
        data,
        defaultColumn,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: {pageSize: 10},
        // dateFormat
    }, useSortBy, useExpanded, usePagination);

    useEffect(() => {
        if (["created_at"].includes(sortBy[0]?.id))
            setSearchParams((prevParams: searchParams) => ({...prevParams, sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}`}));
    }, [sortBy]);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light overflow-x-auto block lg:table">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                {...column.getHeaderProps({className: (column as any).className})}
                            >
                                    <span className='flex items-center'>
                                        <span className="flex-1">
                                            {column.render("Header")}
                                        </span>
                                    </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        // Use a React.Fragment here so the table markup is still valid
                        <React.Fragment key={row.getRowProps().key}>
                            <tr>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({className: (cell.column as any).className, style: {width: cell.column.width, maxWidth: cell.column.maxWidth}})}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                            {/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
                            {row.isExpanded ? (
                                <tr>
                                    <td colSpan={visibleColumns.length} className="border">
                                        {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                            */}
                                        {renderRowSubComponent({row, rowProps: row.getRowProps(), visibleColumns})}
                                    </td>
                                </tr>
                            ) : null}
                        </React.Fragment>
                    );
                })}
                </tbody>
            </table>
        </>
    );
};
